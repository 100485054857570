import React, {  useMemo } from 'react';
import './VerificationCard.css';
import { gameAttributes, idToKeyMap} from '../globalParameters.js'

const ResearchVerificationCard = ({ request, showCount = false, onOpenModal }) => {


 
console.log(showCount);

  const handleClick = () => {
                onOpenModal(request);

};  
const dateFormatter = new Intl.DateTimeFormat('sk-SK', {
  day: '2-digit',
  month: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
});

const formattedDate = request.createdAt ? dateFormatter.format(new Date(request.createdAt)) : 'N/A';

  const cardStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.research?.researchTemplate?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.6), ${attribute.rgbColorFirst}, 0.6))`

    return {
      background: background,
      border: `2px solid ${attribute.hexColorSecond}`,
      backgroundColor: `${attribute.hexColorBackground}`,
      color: '#fff' // Biela textová farba pre lepšiu čitateľnosť
    };
  }, [request.research?.researchTemplate?.gameAttribute?.id]);

  const troopIconStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.research?.researchTemplate?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.3))`

    return {
      background: background,
    };
  }, [request.research?.researchTemplate?.gameAttribute?.id]);

  return (
    <div className={`verification-card`} id={'research-'+request.id} style={cardStyle} onClick={handleClick}>
      <div className="verification-card-header">
      <div className="verification-card-icon-container">
                <img
                    src={`/activity/${request.research?.researchTemplate?.troopTemplate?.iconName}`}
                    className="troop-icon"
                    style={troopIconStyle}
                />
            </div>
        <div className="verification-card-name">
          {request?.research?.researchTemplate?.name}
        </div>
        
        <div className="verification-card-icons">

        {formattedDate}

        </div>
      </div>
    
      <div className="verification-card-footer">

        <div className="verification-card-level">
   
        </div>
      </div>
    </div>
  );
};

export default ResearchVerificationCard;
