// components/SpotCreateForm.js
import React, { useState } from 'react';
import axios from 'axios';

const SpotCreateForm = ({ onSpotCreated }) => {
  const [spot, setSpot] = useState({
    name: '',
    description: '',
    level: 1,
    status: 0,
    lat: 0,
    lon: 0,
    radius: 100,
    frequencyId: 1,
    gameAttributeId: 0  // Ensure this is provided or selected appropriately
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const parsedValue = type === 'number' ? parseFloat(value) || 0 : value;
    setSpot({ ...spot, [name]: parsedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/admin/createSpot', spot);
      onSpotCreated(response.data);
      alert('Spot created successfully!');
      // Reset form to initial state
      setSpot({
        name: '',
        description: '',
        level: 1,
        status: 0,
        lat: 0,
        lon: 0,
        radius: 100,
        frequencyId: 1,
        gameAttributeId: 0
      });
    } catch (error) {
      alert('Failed to create spot');
      console.error('Error creating spot:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Name:
          <input type="text" name="name" value={spot.name} onChange={handleChange} placeholder="Name" />
        </label>
      </div>
      <div>
        <label>
          Description:
          <input type="text" name="description" value={spot.description} onChange={handleChange} placeholder="Description" />
        </label>
      </div>
      <div>
        <label>
          Level:
          <input type="number" name="level" value={spot.level} onChange={handleChange} placeholder="Level" />
        </label>
      </div>
      <div>
        <label>
          Status:
          <input type="number" name="status" value={spot.status} onChange={handleChange} placeholder="Status" />
        </label>
      </div>
      <div>
        <label>
          Latitude:
          <input type="number" name="lat" value={spot.lat} onChange={handleChange} placeholder="Latitude" />
        </label>
      </div>
      <div>
        <label>
          Longitude:
          <input type="number" name="lon" value={spot.lon} onChange={handleChange} placeholder="Longitude" />
        </label>
      </div>
      <div>
        <label>
          Radius (meters):
          <input type="number" name="radius" value={spot.radius} onChange={handleChange} placeholder="Radius" />
        </label>
      </div>
      <div>
        <label>
          Frequency ID:
          <input type="number" name="frequencyId" value={spot.frequencyId} onChange={handleChange} placeholder="Frequency ID" />
        </label>
      </div>
      <div>
        <label>
          Game Attribute ID:
          <input type="number" name="gameAttributeId" value={spot.gameAttributeId} onChange={handleChange} placeholder="Game Attribute ID" />
        </label>
      </div>
      <button type="submit">Create Spot</button>
    </form>
  );
};

export default SpotCreateForm;
