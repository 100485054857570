// components/ActivityLevelTemplateCreateForm.js
import React, { useState } from 'react';
import axios from 'axios';

const ActivityLevelTemplateCreateForm = ({ activityTemplateId, onTemplateCreated }) => {
  const [formValues, setFormValues] = useState({
    level: 0,
    activityTemplateId: activityTemplateId,
    levelNote: '',
    goal: 0,
    requestActiveTime: 0,
    rewardStrengthPoints: 0,
    rewardVitalityPoints: 0,
    rewardInteligencePoints: 0,
    rewardStaminaPoints: 0,
    rewardCharismPoints: 0,
    rewardCombatPoints: 0,
    rewardResilencePoints: 0,
    rewardStrategyPoints: 0,
    rewardUnityPoints: 0,
    rewardGold: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createActivityLevelTemplate/${activityTemplateId}`, formValues);
      alert('Activity Level Template created successfully!');
      onTemplateCreated(response.data); // Notify parent component
      setFormValues({
        ...formValues,
        level: 0,
        levelNote: '',
        goal: 0,
        requestActiveTime: 0,
        rewardStrengthPoints: 0,
        rewardVitalityPoints: 0,
        rewardInteligencePoints: 0,
        rewardStaminaPoints: 0,
        rewardCharismPoints: 0,
        rewardCombatPoints: 0,
        rewardResilencePoints: 0,
        rewardStrategyPoints: 0,
        rewardUnityPoints: 0,
        rewardGold: 0,
      });
    } catch (error) {
      alert('Failed to create activity level template');
      console.error('Error creating activity level template:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Level:
        <input type="number" name="level" value={formValues.level} onChange={handleChange} />
      </label>
      <label>
        Level Note:
        <input type="text" name="levelNote" value={formValues.levelNote} onChange={handleChange} />
      </label>
      <label>
        Goal:
        <input type="number" name="goal" value={formValues.goal} onChange={handleChange} />
      </label>
      <label>
        Request Active Time:
        <input type="number" name="requestActiveTime" value={formValues.requestActiveTime} onChange={handleChange} />
      </label>

      {/* Rewards Section */}
      <h3>Rewards</h3>
      {[
        'Strength', 'Vitality', 'Inteligence', 'Stamina', 'Charism',
        'Combat', 'Resilence', 'Strategy', 'Unity', 'Gold'
      ].map((reward) => (
        <label key={reward}>
          Reward {reward} Points:
          <input
            type="number"
            name={`reward${reward}Points`}
            value={formValues[`reward${reward}Points`]}
            onChange={handleChange}
          />
        </label>
      ))}

      <button type="submit">Create Activity Level Template</button>
    </form>
  );
};

export default ActivityLevelTemplateCreateForm;
