// components/ChallengeTemplateCreateForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChallengeTemplateCreateForm = ({ onChallengeCreated }) => {
  const [challenge, setChallenge] = useState({
    name: '',
    description: '',
    gameAttributeId: 0,
    level: 0,
    challengeTypeId: '',
    activityTemplateId: '',
    activityLevel: 0,
    goal: 0,
    rewardStrengthPoints: 0,
    rewardVitalityPoints: 0,
    rewardInteligencePoints: 0,
    rewardStaminaPoints: 0,
    rewardCharismPoints: 0,
    rewardCombatPoints: 0,
    rewardResilencePoints: 0,
    rewardStrategyPoints: 0,
    rewardUnityPoints: 0,
    rewardGold: 0,
    troopTemplateId: null,
    troopRarityId: null,
    count: null,
    itemTemplateId: null,
    itemRarityId: null,
    costGold: 0,
  });

  const [gameAttributes, setGameAttributes] = useState([]);
  const [challengeTypes, setChallengeTypes] = useState([]);
  const [activityTemplates, setActivityTemplates] = useState([]);
  const [troopTemplates, setTroopTemplates] = useState([]);
  const [itemTemplates, setItemTemplates] = useState([]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response1 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/GameAttributeDomain`);
        setGameAttributes(response1.data);

        const response2 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/ChallengeTypeDomain`);
        setChallengeTypes(response2.data);

        const response3 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/ActivityTemplateDomain`);
        setActivityTemplates(response3.data);


        const response5 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/TroopTemplateDomain`);
        setTroopTemplates(response5.data);

        const response6 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/ItemTemplateDomain`);
        setItemTemplates(response6.data);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };

    fetchDomains();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChallenge({
      ...challenge,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createChallengeTemplate`, challenge);
      alert('Challenge Template created successfully!');
      onChallengeCreated(response.data);
      setChallenge({
        name: '',
        description: '',
        gameAttributeId: 0,
        level: 0,
        challengeTypeId: '',
        activityTemplateId: '',
        activityLevel: 0,
        goal: 0,
        rewardStrengthPoints: 0,
        rewardVitalityPoints: 0,
        rewardInteligencePoints: 0,
        rewardStaminaPoints: 0,
        rewardCharismPoints: 0,
        rewardCombatPoints: 0,
        rewardResilencePoints: 0,
        rewardStrategyPoints: 0,
        rewardUnityPoints: 0,
        rewardGold: 0,
        troopTemplateId: null,
        troopRarityId: null,
        count: null,
        itemTemplateId: null,
        itemRarityId: null,
        costGold: 0,
      });
    } catch (error) {
      alert('Failed to create challenge template');
      console.error('Error creating challenge template:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="name" value={challenge.name} onChange={handleChange} />
      </label>
      <label>
        Description:
        <input type="text" name="description" value={challenge.description} onChange={handleChange} />
      </label>
      <label>
        Game Attribute:
        <select name="gameAttributeId" value={challenge.gameAttributeId} onChange={handleChange}>
          <option value="">Select Game Attribute</option>
          {gameAttributes.map((attr) => (
            <option key={attr.id} value={attr.id}>
              {attr.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Level:
        <input type="number" name="level" value={challenge.level} onChange={handleChange} />
      </label>
      <label>
        Challenge Type:
        <select name="challengeTypeId" value={challenge.challengeTypeId} onChange={handleChange}>
          <option value="">Select Challenge Type</option>
          {challengeTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Activity Template:
        <select name="activityTemplateId" value={challenge.activityTemplateId} onChange={handleChange}>
          <option value="">Select Activity Template</option>
          {activityTemplates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
      </label>
      <label>
      Activity Level:
        <input type="activityLevel" name="activityLevel" value={challenge.activityLevel} onChange={handleChange} />
      </label>
      <label>
        Goal:
        <input type="number" name="goal" value={challenge.goal} onChange={handleChange} />
      </label>

      {/* Rewards Section */}
      <h3>Rewards</h3>
      {['Strength', 'Vitality', 'Inteligence', 'Stamina', 'Charism', 'Combat', 'Resilence', 'Strategy', 'Unity', 'Gold'].map((reward) => (
        <label key={reward}>
          Reward {reward} Points:
          <input type="number" name={`reward${reward}Points`} value={challenge[`reward${reward}Points`]} onChange={handleChange} />
        </label>
      ))}

      {/* Troop and Item Associations */}
      <label>
        Troop Template:
        <select name="troopTemplateId" value={challenge.troopTemplateId} onChange={handleChange}>
          <option value="">Select Troop Template</option>
          {troopTemplates.map((troop) => (
            <option key={troop.id} value={troop.id}>
              {troop.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Troop Rarity ID:
        <input type="number" name="troopRarityId" value={challenge.troopRarityId || ''} onChange={handleChange} />
      </label>
      <label>
        Count:
        <input type="number" name="count" value={challenge.count || ''} onChange={handleChange} />
      </label>

      <label>
        Item Template:
        <select name="itemTemplateId" value={challenge.itemTemplateId} onChange={handleChange}>
          <option value="">Select Item Template</option>
          {itemTemplates.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Item Rarity ID:
        <input type="number" name="itemRarityId" value={challenge.itemRarityId || ''} onChange={handleChange} />
      </label>

      {/* Cost Section */}
      <label>
        Cost Gold:
        <input type="number" name="costGold" value={challenge.costGold} onChange={handleChange} />
      </label>

      <button type="submit">Create Challenge Template</button>
    </form>
  );
};

export default ChallengeTemplateCreateForm;
