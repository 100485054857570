import React from 'react';
import './DraggableCard.css';

const DraggableCard = ({ description, onClick }) => {
  return (
    <div className="generic-card" onClick={onClick}>
      <p>{description}</p>
    </div>
  );
};

export default DraggableCard;