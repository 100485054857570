import React from 'react';
import './TopPanel.css';

import { IoIosChatbubbles } from "react-icons/io";
import { AiFillNotification, AiOutlineLogout } from "react-icons/ai";
import { RiGlobalLine } from "react-icons/ri";


const TopPanelOffMenu = () => {



  return (
    <div className="top-panel">
      <div className="icon-container">
      {/*<HPBar hp={80} maxHp={100} mana={50} maxMana={100} energy={30} maxEnergy={100} />*/ }  
        <RiGlobalLine className={ 'fa-iconT'} />
        <IoIosChatbubbles className={'fa-iconT'} />
        <AiFillNotification className={'fa-iconT'}/>
        <AiOutlineLogout className="fa-iconT" /> {/* Add the logout icon */}
      </div> 
    </div>
  );
};

export default TopPanelOffMenu;
