// components/SpotDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SpotActivitiesTable from './SpotActivitiesTable';
import SpotEnemyTroopsTable from './SpotEnemyTroopsTable';
import SpotEnemyTroopsActualTable from './SpotEnemyTroopsActualTable';
import SpotEditModal from './SpotEditModal';

const SpotDetail = () => {
  const { spotId } = useParams();
  const [spot, setSpot] = useState({});
  const [spotActivities, setSpotActivities] = useState([]);
  const [spotEnemyTroops, setSpotEnemyTroops] = useState([]);
  const [spotEnemyTroopsActual, setSpotEnemyTroopsActual] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedActivityId, setSelectedActivityId] = useState('');
const [activityLevel, setActivityLevel] = useState('');

const [selectedTroopTemplateId, setSelectedTroopTemplateId] = useState('');
const [rarity, setRarity] = useState(0);
const [count, setCount] = useState(0);

  const [activityDomain, setActivityDomain] = useState([]);
  const [troopDomain, setTroopDomain] = useState([]);

useEffect(() => {
  const fetchDomains = async () => {
    try {
      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/spotActivityDomain`);
      setActivityDomain(response.data);

      const responseTroop = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/spotEnemyTroopDomain`);
      setTroopDomain(responseTroop.data);
    } catch (error) {
      console.error('Error fetching activity domain data:', error);
      // Handle errors as needed
    }
  };

  fetchDomains();
}, []);


const handleAddActivity = async (e) => {
  e.preventDefault(); // Prevent default form submission
  try {
    const activityData = {
      SpotId: spotId, // Assuming you have this from the URL or parent state
      ActivityTemplateId: selectedActivityId,
      Level: parseInt(activityLevel, 10) // Ensure level is sent as a number
    };

    const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/admin/addSpotActivity', activityData);
    console.log('Activity added:', response.data);
    // Optionally update UI or state here
  } catch (error) {
    console.error('Failed to add activity:', error);
    // Handle errors appropriately
  }
};

const handleAddTroop = async (e) => {
  e.preventDefault(); // Prevent default form submission
  try {
    const troopData = {
      SpotId: spotId, // Assuming you have this from the URL or parent state
      TroopTemplateId: selectedTroopTemplateId,
      Rarity:  parseInt(rarity, 10),
      Count: parseInt(count, 10) // Ensure level is sent as a number
    };

    const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/admin/addSpotEnemyTroop', troopData);
    console.log('Troop added:', response.data);
    // Optionally update UI or state here
  } catch (error) {
    console.error('Failed to add troop:', error);
    // Handle errors appropriately
  }
};
  // Fetch spot detail function
  const fetchSpotDetail = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        throw new Error('No token found');
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/spot/${spotId}`, { headers });
      setSpot(response.data);
      setSpotActivities(response.data.spotActivities);
      setSpotEnemyTroops(response.data.spotEnemyTroops);
      setSpotEnemyTroopsActual(response.data.spotEnemyTroopActuals);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching spot detail:', error);
      }
    }
  };

  // Fetch spot details on mount
  useEffect(() => {
    fetchSpotDetail();
  }, []);


const deleteActivity = async (activityId) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  try {
    await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/spotActivityStatus/${activityId}`, { headers });
  } catch (error) {
    console.error('Failed to delete activity:', error);
    // Optionally update state or show a notification
  }
};

const deleteTroop = async (troopId) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${token}`
  };

  try {
    await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/spotEnemyTroopStatus/${troopId}`, { headers });

  } catch (error) {
    console.error('Failed to delete troop:', error);
    // Optionally update state or show a notification
  }
};

const handleUpdate = () => {
  fetchSpotDetail(); // Refresh spot data after update
};


const [isEditModalOpen, setEditModalOpen] = useState(false);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Spot Details</h2>
      <p>Name: {spot.name}</p>
      <p>Description: {spot.description}</p>

      <button onClick={() => setEditModalOpen(true)}>Edit Spot</button>

<SpotEditModal
  isOpen={isEditModalOpen}
  onClose={() => setEditModalOpen(false)}
  spot={spot}
  onUpdate={handleUpdate}
/>



      <form onSubmit={handleAddActivity}>
    <select value={selectedActivityId} onChange={e => setSelectedActivityId(e.target.value)}>
      <option value="">Select an Activity</option>
      {activityDomain.map(activity => (
        <option key={activity.id} value={activity.id}>{activity.name}</option>
      ))}
    </select>
    <input type="number" value={activityLevel} onChange={e => setActivityLevel(e.target.value)} placeholder="Level" />
    <button type="submit">Add Activity</button>
  </form>



      {spotActivities && <SpotActivitiesTable activities={spotActivities} onDelete={deleteActivity} />}

      <br></br>
<br></br><br></br>
<br></br>
  <form onSubmit={handleAddTroop}>
    <select value={selectedTroopTemplateId} onChange={e => setSelectedTroopTemplateId(e.target.value)}>
      <option value="">Select an TroopTemplate</option>
      {troopDomain.map(troop => (
        <option key={troop.id} value={troop.id}>{troop.name}</option>
      ))}
    </select>
    <input type="number" value={rarity} onChange={e => setRarity(e.target.value)} placeholder="Rarity" />
    <input type="number" value={count} onChange={e => setCount(e.target.value)} placeholder="Count" />
    <button type="submit">Add Troop</button>
  </form>


      {spotEnemyTroops && <SpotEnemyTroopsTable troops={spotEnemyTroops} onDelete={deleteTroop} />}

      <br></br>
<br></br>


{spotEnemyTroopsActual && <SpotEnemyTroopsActualTable troops={spotEnemyTroopsActual } />}


<br></br>
<br></br>


<br></br>
<br></br>


<br></br>
<br></br>


<br></br>
<br></br>

    </div>

    
  );
};

export default SpotDetail;
