// components/ChallengeTemplateTable.js
import React, { useState } from 'react';
import axios from 'axios';

const ChallengeTemplateTable = ({ challenges, setChallenges }) => {
  const [editingChallenge, setEditingChallenge] = useState(null);

  const handleEditClick = (challenge) => {
    setEditingChallenge(challenge);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/updateChallengeTemplate/${editingChallenge.id}`, editingChallenge);
      setChallenges((prev) => prev.map((ch) => (ch.id === editingChallenge.id ? response.data : ch)));
      setEditingChallenge(null); // Close the edit form
    } catch (error) {
      console.error('Error updating challenge template:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingChallenge((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Game Attribute ID</th>
            <th>Level</th>
            <th>Challenge Type ID</th>
            <th>Activity Template ID</th>
            <th>Activity Level</th>
            <th>Goal</th>
            <th>Reward Strength Points</th>
            <th>Reward Vitality Points</th>
            <th>Reward Inteligence Points</th>
            <th>Reward Stamina Points</th>
            <th>Reward Charism Points</th>
            <th>Reward Combat Points</th>
            <th>Reward Resilence Points</th>
            <th>Reward Strategy Points</th>
            <th>Reward Unity Points</th>
            <th>Reward Gold</th>
            <th>Troop Template ID</th>
            <th>Troop Rarity ID</th>
            <th>Count</th>
            <th>Item Template ID</th>
            <th>Item Rarity ID</th>
            <th>Cost Gold</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {challenges.map((challenge) => (
            <tr key={challenge.id}>
              <td>{challenge.name}</td>
              <td>{challenge.description}</td>
              <td>{challenge.gameAttributeId}</td>
              <td>{challenge.level}</td>
              <td>{challenge.challengeTypeId}</td>
              <td>{challenge.activityTemplateId}</td>
              <td>{challenge.activityLevel}</td>
              <td>{challenge.goal}</td>
              <td>{challenge.rewardStrengthPoints}</td>
              <td>{challenge.rewardVitalityPoints}</td>
              <td>{challenge.rewardInteligencePoints}</td>
              <td>{challenge.rewardStaminaPoints}</td>
              <td>{challenge.rewardCharismPoints}</td>
              <td>{challenge.rewardCombatPoints}</td>
              <td>{challenge.rewardResilencePoints}</td>
              <td>{challenge.rewardStrategyPoints}</td>
              <td>{challenge.rewardUnityPoints}</td>
              <td>{challenge.rewardGold}</td>
              <td>{challenge.troopTemplateId}</td>
              <td>{challenge.troopRarityId}</td>
              <td>{challenge.count}</td>
              <td>{challenge.itemTemplateId}</td>
              <td>{challenge.itemRarityId}</td>
              <td>{challenge.costGold}</td>
              <td>
                <button onClick={() => handleEditClick(challenge)}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingChallenge && (
        <form onSubmit={handleUpdateSubmit}>
          <h3>Update Challenge Template</h3>
          <label>
            Name:
            <input type="text" name="name" value={editingChallenge.name} onChange={handleChange} />
          </label>
          <label>
            Description:
            <input type="text" name="description" value={editingChallenge.description} onChange={handleChange} />
          </label>
          <label>
            Game Attribute ID:
            <input type="number" name="gameAttributeId" value={editingChallenge.gameAttributeId} onChange={handleChange} />
          </label>
          <label>
            Level:
            <input type="number" name="level" value={editingChallenge.level} onChange={handleChange} />
          </label>
          <label>
            Challenge Type ID:
            <input type="text" name="challengeTypeId" value={editingChallenge.challengeTypeId} onChange={handleChange} />
          </label>
          <label>
            Activity Template ID:
            <input type="text" name="activityTemplateId" value={editingChallenge.activityTemplateId} onChange={handleChange} />
          </label>
          <label>
            Activity Level:
            <input type="number" name="activityLevel" value={editingChallenge.activityLevel} onChange={handleChange} />
          </label>
          <label>
            Goal:
            <input type="number" name="goal" value={editingChallenge.goal} onChange={handleChange} />
          </label>
          {/* Reward fields */}
          {['Strength', 'Vitality', 'Inteligence', 'Stamina', 'Charism', 'Combat', 'Resilence', 'Strategy', 'Unity', 'Gold'].map((reward) => (
            <label key={reward}>
              Reward {reward} Points:
              <input
                type="number"
                name={`reward${reward}Points`}
                value={editingChallenge[`reward${reward}Points`]}
                onChange={handleChange}
              />
            </label>
          ))}
          <label>
            Troop Template ID:
            <input type="text" name="troopTemplateId" value={editingChallenge.troopTemplateId || ''} onChange={handleChange} />
          </label>
          <label>
            Troop Rarity ID:
            <input type="number" name="troopRarityId" value={editingChallenge.troopRarityId || ''} onChange={handleChange} />
          </label>
          <label>
            Count:
            <input type="number" name="count" value={editingChallenge.count || ''} onChange={handleChange} />
          </label>
          <label>
            Item Template ID:
            <input type="text" name="itemTemplateId" value={editingChallenge.itemTemplateId || ''} onChange={handleChange} />
          </label>
          <label>
            Item Rarity ID:
            <input type="number" name="itemRarityId" value={editingChallenge.itemRarityId || ''} onChange={handleChange} />
          </label>
          <label>
            Cost Gold:
            <input type="number" name="costGold" value={editingChallenge.costGold} onChange={handleChange} />
          </label>
          <button type="submit">Save Changes</button>
          <button onClick={() => setEditingChallenge(null)}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default ChallengeTemplateTable;
