import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CombatInteraction from './CombatInteraction';
import Troop from './Troop';
import './CombatSelectionScreen.css';
import TroopSelectionModal from './TroopSelectionModal';

import CardMenuButton from '../Planet/CardMenuButton'; // Ensure correct import path



const CombatSelectionScreen = () => {



    const [characterTroops, setCharacterTroops] = useState([]);
    const [combatTroops, setCombatTroops]= useState([]);
    const [spotTroops, setSpotTroops] = useState([]);
    const [spot, setSpot] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [combatResults, setCombatResults] = useState(null);


    const { spotId } = useParams();


    const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedTroop, setSelectedTroop] = useState(null);

const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

const [activeCard, setActiveCard] = useState('characterTroops');
const [action, setAction]= useState("");

const scrollRef = useRef(null);





const handleOpenModal = (troop, action) => {
    const element = document.getElementById(`troop-${troop.id}`);
    if (!element) {
        console.error('Element not found');
        return;
    }
    setError("");
    const rect = element.getBoundingClientRect();

    const absolutePositionY = rect.top + scrollRef.current.scrollTop;

    setAction(action);
    setSelectedTroop(troop);
    setModalPosition({ x: rect.left, y: absolutePositionY });
    setIsModalOpen(true);
};
const handleCloseModal = () => {
    setIsModalOpen(false);
    setAction("");
};








    useEffect(() => {
        const fetchCharacterTroops = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage.');
                    return;
                }

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };

                const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/troop/characterTroops', { headers });
                setCharacterTroops(response.data);
                console.log(response.data);
                setIsLoading(false);

            } catch (error) {
                console.log("error", error);
                if (error.response && error.response.status === 401) {
                  window.location.href = '/login';
                } else {
                  console.error('Error fetching character troops:', error);
                }
              }
            };

        fetchCharacterTroops();
    }, []);


    

    useEffect(() => {
        const fetchSpotTroops = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage.');
                    return;
                }

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };

                const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/troop/spotTroops/'+spotId, { headers });
                setSpotTroops(response.data);
                console.log(response.data);
                setIsLoading(false);

            } catch (error) {
                console.log("error", error);
                if (error.response && error.response.status === 401) {
                  window.location.href = '/login';
                } else {
                  console.error('Error fetching spot troops:', error);
                }
              }
            }; 

        fetchSpotTroops();
    }, []);


    useEffect(() => {
        const fetchSpotDetail = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage.');
                    return;
                }

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };

                const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/combat/spotDetail/'+ spotId, { headers });
                setSpot(response.data);
                setIsLoading(false);

    
            } catch (error) {
                console.log("error", error);
                if (error.response && error.response.status === 401) {
                  window.location.href = '/login';
                } else {
                  console.error('Error fetching spot details:', error);
                }
              }
            };
        fetchSpotDetail();
    }, []);

    const startCombat = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found in local storage.');
            return;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };
            
            // Zmena z [] na {}, pre správne použitie ako mapu
            const aggregatedTroops = {};
    
            combatTroops.filter(troop => troop.count > 0).forEach(troop => {
                const templateId = troop.troopTemplateId;
    
                // Skontrolujeme, či už máme v akumulátore záznam pre danú šablónu
                if (!aggregatedTroops[templateId]) {
                    // Ak nie, vytvoríme nový záznam
                    aggregatedTroops[templateId] = {
                        TroopTemplateId: templateId,
                        Basic: 0,
                        Rare: 0,
                        Epic: 0,
                        Legendary: 0,
                        Cosmic: 0
                    };
                }
    
                // Pripočítame počet jednotiek k príslušnej kategórii rarity
                if (aggregatedTroops[templateId][troop.rarity] !== undefined) {
                    aggregatedTroops[templateId][troop.rarity] += troop.count;
                } else {
                    console.warn(`Rarity ${troop.rarity} for troop template ID ${templateId} is not defined.`);
                }
            });
    
            // Prevedieme objekt na pole objektov pre API
            const troopsArray = Object.values(aggregatedTroops);
    
            // Payload pripravený na odoslanie
            const payload = {
                SpotId: spot.id, // make sure `spotId` is defined somewhere in your component
                Troops: troopsArray,
            };
            
            console.log(payload);
            var response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/combat/start', payload, { headers });
            console.log(response.data);
            setCombatResults(response.data);
            setCombatTroops(response.data.attacker);
            setSpotTroops(response.data.defender);
    
            alert('Combat started successfully!');
        } catch (error) {
            console.log("error", error);
            if (error.response && error.response.status === 401) {
              window.location.href = '/login';
            } else {
              console.error('Error starting combat:', error);
            }
          }
        };
    

    const addTroopToCombat = (troop, sliderValue) => {
        // Najprv nájdeme index vojaka v poli characterTroops
        const troopIndex = characterTroops.findIndex(t => t.id === troop.id);
        if (troopIndex === -1) {
            console.error("Vojak nebol nájdený v characterTroops");
            return;
        }
    
        const troopCombatIndex = combatTroops.findIndex(t => t.id === troop.id);
        const newCharacterTroops = [...characterTroops];
        newCharacterTroops[troopIndex] = {...newCharacterTroops[troopIndex], count: newCharacterTroops[troopIndex].count - sliderValue};
        
        if (troopCombatIndex === -1) {
            // Vojak nie je v combatTroops, pridáme ho
            const newTroop = {...characterTroops[troopIndex], count: sliderValue};
            setCombatTroops(currentTroops => [...currentTroops, newTroop]);
        } else {
            // Vojak je už v combatTroops, aktualizujeme len jeho počet
            const newCombatTroops = [...combatTroops];
            newCombatTroops[troopCombatIndex] = {...newCombatTroops[troopCombatIndex], count: newCombatTroops[troopCombatIndex].count + sliderValue};
            setCombatTroops(newCombatTroops);
        }
    
        // Aktualizujeme characterTroops s novým počtom
        setCharacterTroops(newCharacterTroops);
    
        console.log("updatedTroop", newCharacterTroops[troopIndex]);
        console.log("characterTroops after update", newCharacterTroops);
        console.log("combatTroops after update", combatTroops);
    };

    const deleteTroopToCombat = (troop, sliderValue) => {
        // Najprv nájdeme index vojaka v poli characterTroops
        const troopIndex = characterTroops.findIndex(t => t.id === troop.id);
        if (troopIndex === -1) {
            console.error("Vojak nebol nájdený v characterTroops");
            return;
        }
    
        const troopCombatIndex = combatTroops.findIndex(t => t.id === troop.id);
        const newCharacterTroops = [...characterTroops];
        newCharacterTroops[troopIndex] = {...newCharacterTroops[troopIndex], count: newCharacterTroops[troopIndex].count + sliderValue};
        
        if (troopCombatIndex === -1) {
            // Vojak nie je v combatTroops, pridáme ho
            const newTroop = {...characterTroops[troopIndex], count: sliderValue};
            setCombatTroops(currentTroops => [...currentTroops, newTroop]);
        } else {
            // Vojak je už v combatTroops, aktualizujeme len jeho počet
            const newCombatTroops = [...combatTroops];
            newCombatTroops[troopCombatIndex] = {...newCombatTroops[troopCombatIndex], count: newCombatTroops[troopCombatIndex].count - sliderValue};
            setCombatTroops(newCombatTroops);
        }
    
        // Aktualizujeme characterTroops s novým počtom
        setCharacterTroops(newCharacterTroops);
    
        console.log("updatedTroop", newCharacterTroops[troopIndex]);
        console.log("characterTroops after update", newCharacterTroops);
        console.log("combatTroops after update", combatTroops);
    };
    

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (

<div className="combat-selection-screen" ref={scrollRef}>
    <div className="menu">
             <div className="menu-buttons">
                    <CardMenuButton title="Dostupné" onClick={() => setActiveCard('characterTroops')} />
                    <CardMenuButton title="Bojové" onClick={() => setActiveCard('combatTroops')} />
                    <CardMenuButton title="Nepriateľské" onClick={() => setActiveCard('enemyTroops')} />
             </div>
        </div>
    <h2>Combat Selection</h2>
    <div className="troop-list">
    {selectedTroop && (
            <TroopSelectionModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                troop={selectedTroop}
                onAddTroopToCombat={addTroopToCombat}
                onDeleteTroopToCombat = {deleteTroopToCombat}
                action = {action}
                style={{
                    position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
                    top: `${modalPosition.y}px`,
                    transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
                }}
                styleOverlay={{
                    height: `${modalPosition.y + 2000}px`
                }}
            />
        )}

{activeCard === 'characterTroops' && characterTroops && characterTroops
    .filter(troop => troop.count > 0)
    .map((troop) => (
        <Troop
            key={troop.id}
            id={`troop-${troop.id}`}
            troop={troop}
            modalActive={true}
            action={"add"}
            onOpenModal={handleOpenModal}
        />
    )
)}
   {activeCard === 'combatTroops' && combatTroops && combatTroops.filter(troop => troop.count > 0 || troop.countAfter >=0 ).map((troop) => (

            <Troop  key={troop.id}  id={`troop-${troop.id}`}  troop={troop} modalActive={true} action={"delete"} onOpenModal={handleOpenModal} />

      
        ))}

{activeCard === 'combatTroops' && !combatResults && combatTroops.some(troop => troop.count > 0) &&  (
    <button onClick={startCombat}>Start Combat</button>
)}
 

        {activeCard === 'enemyTroops' && spotTroops && spotTroops.map(enemy => (
                     <Troop  key={enemy.id}  id={`troop-${enemy.id}`}  troop={enemy} modalType={false} onOpenModal={handleOpenModal} />
                ))}


    </div>
    {activeCard === 'combatTroops' && combatTroops && combatResults && 
     <CombatInteraction summary={combatResults} /> 
      }

               
             

            </div>

    );
};

export default CombatSelectionScreen;
