import React, { useRef, useEffect } from 'react';
/*import './GenerateSignatureModal.css';*/

const BuyResearchModal = ({ isOpen, onClose, onConfirm, research, style, styleOverlay }) => {
  const modalContentRef = useRef(null); // Reference to the modal content

  // Function to handle click events outside the modal
  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      onClose(); // Close the modal if the click is outside the modal content
    }
  };

  useEffect(() => {
    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="research-verification-modal-overlay" style={styleOverlay}>
      <div className="research-verification-modal-content" ref={modalContentRef} style={style}>
        <h3>Výskum jednotky {research.name}</h3>
        <p>Chceš začať výskum jednotky za {research.cost}?</p>
        <div className="modal-buttons">
          <button className="styled-button" onClick={onConfirm}>Áno</button>
          <button className="styled-button" onClick={onClose}>Nie</button>
        </div>
      </div>
    </div>
  );
};

export default BuyResearchModal;
