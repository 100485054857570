import React, { useMemo } from 'react';

import './ResearchCard.css';
import { gameAttributes, idToKeyMap} from '../globalParameters.js'

const ResearchCard = ({ research, showCount = false, onOpenModal }) => {


  const levelTemplate = /* research.researchId && research.research[0]; TODO*/ 2;


  const handleClick = () => {
                onOpenModal(research);

};  
 
console.log(showCount);

  const cardStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.6), ${attribute.rgbColorFirst}, 0.6))`

    return {
      background: background,
      border: `2px solid ${attribute.hexColorSecond}`,
      backgroundColor: `${attribute.hexColorBackground}`,
      color: '#fff' // Biela textová farba pre lepšiu čitateľnosť
    };
  }, [research.gameAttribute.id]);

  const troopIconStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.3))`

    return {
      background: background,
    };
  }, [research.gameAttribute.id]);

  return (
    <div className={`research-card`} id={'research-'+research.id} style={cardStyle} onClick={handleClick}>
      <div className="research-card-header">
      <div className="research-icon-container">
                <img
                    src={`/activity/${research.iconName}`}
                    className="troop-icon"
                    style={troopIconStyle}
                />
            </div>
        <div className="research-name">
          {research.name}
        </div>
        
        <div className="research-icons">
        </div>
      </div>
      <div className="research-description">
        {research.description}
      </div>
      <div className="research-footer">
        <div className="research-improvements">

                {research.countBasic > 0 && (
                    <div className="research-troop-count basic">
                        {research.countBasic}
                    </div>
                )}
                {research.countRare > 0 && (
                    <div className="research-troop-count rare">
                        {research.countRare}
                    </div>
                )}
                {research.countEpic > 0 && (
                    <div className="research-troop-count epic">
                        {research.countEpic}
                    </div>
                )}
                {research.countLegendary > 0 && (
                    <div className="research-troop-count legendary">
                        {research.countLegendary}
                    </div>
                )}
                {research.countCosmic > 0 && (
                    <div className="research-troop-count cosmic">
                        {research.countCosmic}
                    </div>
                )}

        </div>
        <div className="research-level">
          {levelTemplate ? levelTemplate.level : 1}
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
