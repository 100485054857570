// Definícia farieb
export const gameAttributes = {
    strength: { id: '1', name:'Sila', hexColorFirst: '#4a4a4a' , hexColorSecond: '#5c5b5b' , rgbColorFirst: 'rgb(74,74,74', rgbColorSecond:'rgb(92,91,92' , nameIcon: "sila.png", 
          gameLevelAttributes: [
           {level: "1", goalPoints:"100"}, 
           {level: "2", goalPoints:"200"},
           {level: "3", goalPoints:"300"},
           {level: "4", goalPoints:"500"},
           {level: "5", goalPoints:"800"},
           {level: "6", goalPoints:"1300"},
           {level: "7", goalPoints:"2100"},
           {level: "8", goalPoints:"3400"},
           {level: "9", goalPoints:"5500"},
           {level: "10", goalPoints:"8900"},
           {level: "11", goalPoints:"144000"},
           {level: "12", goalPoints:"233000"},
           {level: "13", goalPoints:"377000"},
           {level: "14", goalPoints:"610000"},
           {level: "15", goalPoints:"987000"},
          ]
        },
    vitality: { id: '2', name:'Vitalita', hexColorFirst: '#ed2939' , hexColorSecond:  '#ff5c7a', rgbColorFirst: 'rgb(237,41,57', rgbColorSecond:   'rgb(255,92,122', nameIcon: "vitalita.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    inteligence: { id: '3', name:'Múdrosť', hexColorFirst: '#00a3ff', hexColorSecond:   '#66cfff', rgbColorFirst: 'rgb(0,163,255', rgbColorSecond:  'rgb(102,207,255', nameIcon: "mudrost.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    charism: { id: '4', name:'Charizma', hexColorFirst:'#f2e124' , hexColorSecond:  '#f7e77e', rgbColorFirst: 'rgb(242,225,36' , rgbColorSecond:  'rgb(247,231,126', nameIcon: "charizma.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    stamina: { id: '5', name:'Výdrž', hexColorFirst:'#30ebd2'  , hexColorSecond:  '#6befbc', rgbColorFirst:'rgb(48,235,210', rgbColorSecond:  'rgb(107,239,188', nameIcon: "vydrz.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    combat: { id: '6', name:'Súboj', hexColorFirst: '#96b7cb', hexColorSecond: '#c4d1e7', rgbColorFirst: 'rgb(150,183,203', rgbColorSecond: 'rgb(196,209,231', nameIcon: "suboj.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    resilence: { id: '7', name:'Odolnosť', hexColorFirst: '#A020F0' , hexColorSecond:'#D783FF', rgbColorFirst: 'rgb(160,32,240', rgbColorSecond:  'rgb(215,131,255', nameIcon: "odolnost.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    strategy: { id: '8', name:'Stratégia', hexColorFirst: '#f05657', hexColorSecond: '#f08000', rgbColorFirst: 'rgb(240,86,87', rgbColorSecond: 'rgb(240,128,0', nameIcon: "strategia.png", 
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
    unity: { id: '9', name:'Jednota', hexColorFirst:  '#00cc00' , hexColorSecond: '#00e500', rgbColorFirst: 'rgb(0,204,0' , rgbColorSecond: 'rgb(0,229,0', nameIcon: "jednota.png",  
      gameLevelAttributes: [
       {level: "1", goalPoints:"100"}, 
       {level: "2", goalPoints:"200"},
       {level: "3", goalPoints:"300"},
       {level: "4", goalPoints:"500"},
       {level: "5", goalPoints:"800"},
       {level: "6", goalPoints:"1300"},
       {level: "7", goalPoints:"2100"},
       {level: "8", goalPoints:"3400"},
       {level: "9", goalPoints:"5500"},
       {level: "10", goalPoints:"8900"},
       {level: "11", goalPoints:"144000"},
       {level: "12", goalPoints:"233000"},
       {level: "13", goalPoints:"377000"},
       {level: "14", goalPoints:"610000"},
       {level: "15", goalPoints:"987000"},
      ]
    },
   gold: {
      id: "10", name: "Zlato", hexColorFirst: "#c5a34f", hexColorSecond: "#dbb658", rgbColorFirst: "rgb(255, 215, 0", rgbColorSecond: "rgb(230, 194, 0", nameIcon: "zlato.png"
  }
  };

  export  const idToKeyMap = {
    '1': 'strength',
    '2': 'vitality',
    '3': 'inteligence',
    '4': 'charism',
    '5': 'stamina',
    '6': 'combat',
    '7': 'resilence',
    '8': 'strategy',
    '9': 'unity',
    '10': 'gold'
  };