import React, { useState, useRef, useEffect } from 'react';
import './VerificationModal.css';
import axios from 'axios';

import KeyIconNumbers from '../Market/KeyIconNumbers';

const VerificationModal = ({ isOpen, onClose, spot, Key, onCreateVerificationRequest, handleVideoUpload }) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Ensure overlayRef.current exists before adding event listener
    if (overlayRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]); // Only re-run if onClose changes

  if (!isOpen) return null;

  const handleCreateVerificationRequest = async () => {
    setUploading(true);
    setUploadMessage('');
    try {
      const verificationRequestId = await onCreateVerificationRequest(spot.id, Key, text, url, videoFile);
      setUploadMessage('Verification request created successfully.');
      if (videoFile) {
        await handleVideoUpload(spot.id, verificationRequestId, videoFile);
        setUploadMessage('Video uploaded successfully.');
      }
      window.location.reload();
    } catch (error) {
      console.log('Error in creating or uploading:', error);
      setUploadMessage('Error uploading video or creating verification request.');
    } finally {
      setUploading(false);
    }
  };


  const cancelSpotRequest= async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found. Please log in.');
        // Redirect to login or refresh the token
        return;
      }
  console.log("xxx");
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/cancelSpotRequest/${spot.id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      });
      console.log("xxxzzz");
      console.log('Spot Request cancelled successfully:', response.data);
      window.location.reload();
      onClose(); // Close the modal on successful cancellation
   
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('failed to cancel request:', error);
      }
    }
  };



  return (
    <div className="verification-modal-overlay" ref={overlayRef}>
      <div className="verification-modal-content">
        <h3>Verifikácia pre: {spot.name}</h3>
        
        <div className="detail-item"><KeyIconNumbers numbers={Key} /></div>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Ak treba, pridaj text..."
          className="styled-input"
        />
        <input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Vlož URL adresu..."
          className="styled-input"
        />
        <input
          type="file"
          onChange={(e) => setVideoFile(e.target.files[0])}
          accept="video/*"
          className="styled-input"
        />
        <button onClick={handleCreateVerificationRequest} disabled={uploading} className="styled-button">
          {uploading ? 'Spracovanie...' : 'Poslať verifikáciu'}
        </button>
        <button onClick={onClose} className="styled-button">Zatvoriť</button>
        <button onClick={cancelSpotRequest} className="styled-button">Zrušiť request</button>
        {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
      </div>
    </div>
  );
};

export default VerificationModal;
