// components/AdminPage.js
import React, { useEffect, useState } from 'react';
import SpotTable from './SpotTable';
import SpotCreateForm from './SpotCreateForm';
import axios from 'axios';



const AdminPage = () => {
  const [spots, setSpots] = useState([]);



  useEffect(() => {
    const notification = sessionStorage.getItem('notification');
    if (notification) {
      alert(notification); // Replace this with your notification system
      sessionStorage.removeItem('notification');
    }
  const fetchSpots = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        throw new Error('No token found');
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/admin/allspots', { headers });
      setSpots(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching spells:', error);

      }
    }
  };

  fetchSpots();
}, []);

  const handleSpotCreated = (newSpot) => {
    setSpots([...spots, newSpot]);
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <SpotTable spots={spots} />
      <SpotCreateForm onSpotCreated={handleSpotCreated} />
    </div>
  );
};

export default AdminPage;
