import React from 'react';
import './SpotActivitiesTable.css';

// SpotActivitiesTable Component
const SpotActivitiesTable = ({ activities, onDelete }) => {
  return (
    <table className="spot-activities-table">
      <thead>
        <tr>
          <th>Activity Name</th>
          <th>Level</th>
          <th>Goal</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {activities.map(activity => (
          <tr key={activity.id}>
            <td>{activity.activityTemplate.name}</td>
            <td>{activity.activityLevelTemplate.level}</td>
            <td>{activity.activityLevelTemplate.goal}</td>
            <td>
            <button onClick={() => onDelete(activity.id)}>
  {activity.status === 1 ? "Deactivate" : "Activate"}
</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpotActivitiesTable;
