import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import OnlineEventCard from './OnlineEventCard';
import CardMenuButton from '../Planet/CardMenuButton';
import { FaFilter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import RegisterEventModal from './RegisterEventModal';
import UnregisterEventModal from './UnregisterEventModal';

const OnlineEvents = () => {
  
 /* const [researchs, setResearchs] = useState([]);
  



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [generatedSignature, setGeneratedSignature] = useState(null);

*/

 const [events, setEvents] = useState([]);
 const [error, setError] = useState(null);
 const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
 const [selectedEvent, setSelectedEvent] = useState(null);

   const [isRegisterEventModalOpen, setIsRegisterEventModalOpen] = useState (false);
   const [isUnregisterEventModalOpen, setIsUnregisterEventModalOpen] = useState (false);


 const scrollRef = useRef(null);
  const navigate = useNavigate(); 
 
 
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/onlineEvents', { headers });
        console.log("events", response.data);
        setEvents(response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error fetching researchs:', error);
        setError(error.message);
        }
      }
    };

    fetchEvents();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }


  const handleOpenRegisterModal = (event) => {

    const element = document.getElementById(`event-${event.id}`);
    if (!element) {
        console.error('Element not found');
        return;
    }
   
    const rect = element.getBoundingClientRect();

    const absolutePositionY = rect.top + scrollRef.current.scrollTop;

    setSelectedEvent(event);
    setIsRegisterEventModalOpen(true);
    setModalPosition({ x: rect.left, y: absolutePositionY });
};

const handleOpenUnregisterModal = (event) => {

  const element = document.getElementById(`event-${event.id}`);
  if (!element) {
      console.error('Element not found');
      return;
  }
 
  const rect = element.getBoundingClientRect();

  const absolutePositionY = rect.top + scrollRef.current.scrollTop;

  setSelectedEvent(event);
  setIsUnregisterEventModalOpen(true);
  setModalPosition({ x: rect.left, y: absolutePositionY });
};


const registerToEvent = async () => {
  try {
    console.log(selectedEvent);
    const token = localStorage.getItem('token');
    const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/onlineEventRegister/${selectedEvent.id}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    console.log(response);
    setIsRegisterEventModalOpen(false);
    window.location.reload();
  } catch (error) {

    console.log("error", error);
    if (error.response && error.response.status === 400 && error.response.data === "Nedostatok zlata") {
     
      alert('Nedostatok zlata');
      // Zobrazenie upozornenia prehliadača
    }
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } else {
    console.error('Error register to event:', error);
    }
  }
};

const unregisterFromEvent = async () => {
  try {
    console.log(selectedEvent);
    const token = localStorage.getItem('token');
    const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/onlineEventUnregister/${selectedEvent.id}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    console.log(response);
    window.location.reload();
  } catch (error) {
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } else {
    console.error('Error unregister from event:', error);
    }
  }
};

  return (
    <div className="container-screen" ref={scrollRef}>
       <div className="menu">
          <CardMenuButton title="Mapa" onClick={() => navigate('/g/mapSpots')} />
          <CardMenuButton title="Akcie" onClick={() => navigate('/g/events')}  />
          <CardMenuButton title="Online" onClick={() => navigate('/g/onlineEvents')}  />
      </div>
      <FaFilter className="filter-icon" /*onClick={toggleFilterPopup}*/ />
       <div className="research-cards-container">
        {events.map((event) => (
          <OnlineEventCard 
             key={event.id} 
             id={`event-${event.id}`} 
             event={event}
             onOpenModalRegister={() => handleOpenRegisterModal(event)}
             onOpenModalUnregister={() => handleOpenUnregisterModal(event)}
             />
        ))}

     </div>
     {isRegisterEventModalOpen && (
        <RegisterEventModal
          isOpen={isRegisterEventModalOpen}
          onClose={() => setIsRegisterEventModalOpen(false)}
          onConfirm={registerToEvent}
          actEvent = {selectedEvent}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left: '50%', 
            width:'80%',
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}

      {isUnregisterEventModalOpen && (
        <UnregisterEventModal
          isOpen={isUnregisterEventModalOpen}
          onClose={() => setIsUnregisterEventModalOpen(false)}
          onConfirm={unregisterFromEvent}
          actEvent = {selectedEvent}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left: '50%', 
            width:'80%',
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}
    </div>
  );
};

export default OnlineEvents;
