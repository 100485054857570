import React from 'react';
import './Troop.css';  // Uistite sa, že cesta k CSS súboru je správna

const Troop = ({ troop, modalActive, action, onOpenModal  }) => {
    console.log(troop.rarity);
    console.log(`troop-card ${troop.rarity.toLowerCase()}`);
    const cardClass = `troop-card ${troop.rarity.toLowerCase()}`;
    const unitTypeClass = `unit-type-icon ${troop.troopType == 1 ? 'sword-icon' : troop.troopType == 2 ? 'bow-icon' : 'catapult-icon'}`;

    const handleClick = () => {
        if(modalActive){
            if (typeof onOpenModal === 'function') {
                console.log(onOpenModal);
                if (troop.count) {
                    onOpenModal(troop, action);
                }
                
            } else {
                console.error('onOpenModal is not a function', onOpenModal);
            }
        }
      
    };  
    return (
        <div className={cardClass} id={`troop-${troop.id}`} onClick={handleClick}>
            <div className="troop-header">
                <div className="level-icon">
                    {troop.level}
                </div>
                <div className="troop-name">{troop.troopTemplateName}</div>
                <div className={unitTypeClass}>
                    <div className="unit-type-image" />
                </div>
            </div>
            <div className="troop-icon-container">
                <img
                    src={`/activity/${troop.iconName}`}
                    alt={troop.troopTemplateName}
                    className="troop-icon"
                    style={{ borderColor: troop.gameAttributeColor }}
                />
            </div>
           {troop.count && <div className='troop-count'>{troop.count} </div>}
           {troop.countBefore && <div className='troop-count'>{troop.countBefore}  <br></br> {troop.countAfter} </div>}
        </div>
    );
};

export default Troop;