// components/ActivityLevelTemplateTable.js
import React, { useState } from 'react';
import axios from 'axios';

const ActivityLevelTemplateTable = ({ activityLevelTemplates }) => {
  const [editingTemplate, setEditingTemplate] = useState(null); // Holds the template currently being edited
  const [formValues, setFormValues] = useState({}); // Holds the values for the editing form

  const handleEditClick = (template) => {
    setEditingTemplate(template);
    setFormValues({ ...template }); // Initialize form values with the selected template data
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://dakujemorg-002-site1.ftempurl.com/api/admin/updateActivityLevelTemplate/${editingTemplate.id}`,
        formValues
      );
      // Update the list with the modified entry
  console.log(response);
      setEditingTemplate(null); // Close the edit form
      alert('Activity Level Template updated successfully!');
    } catch (error) {
      console.error('Error updating activity level template:', error);
      alert('Failed to update activity level template');
    }
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Level</th>
            <th>Level Note</th>
            <th>Goal</th>
            <th>Request Active Time</th>
            <th>Strength Points</th>
            <th>Vitality Points</th>
            <th>Inteligence Points</th>
            <th>Stamina Points</th>
            <th>Charism Points</th>
            <th>Combat Points</th>
            <th>Resilence Points</th>
            <th>Strategy Points</th>
            <th>Unity Points</th>
            <th>Gold</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {activityLevelTemplates.map((template) => (
            <tr key={template.id}>
              <td>{template.level}</td>
              <td>{template.levelNote}</td>
              <td>{template.goal}</td>
              <td>{template.requestActiveTime}</td>
              <td>{template.rewardStrengthPoints}</td>
              <td>{template.rewardVitalityPoints}</td>
              <td>{template.rewardInteligencePoints}</td>
              <td>{template.rewardStaminaPoints}</td>
              <td>{template.rewardCharismPoints}</td>
              <td>{template.rewardCombatPoints}</td>
              <td>{template.rewardResilencePoints}</td>
              <td>{template.rewardStrategyPoints}</td>
              <td>{template.rewardUnityPoints}</td>
              <td>{template.rewardGold}</td>
              <td>
                <button onClick={() => handleEditClick(template)}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingTemplate && (
        <form onSubmit={handleUpdateSubmit}>
          <h3>Update Activity Level Template</h3>
          <label>
            Level:
            <input type="number" name="level" value={formValues.level} onChange={handleChange} />
          </label>
          <label>
            Level Note:
            <input type="text" name="levelNote" value={formValues.levelNote} onChange={handleChange} />
          </label>
          <label>
            Goal:
            <input type="number" name="goal" value={formValues.goal} onChange={handleChange} />
          </label>
          <label>
            Request Active Time:
            <input type="number" name="requestActiveTime" value={formValues.requestActiveTime} onChange={handleChange} />
          </label>

          {/* Rewards Section */}
          <h3>Rewards</h3>
          {[
            'Strength', 'Vitality', 'Inteligence', 'Stamina', 'Charism',
            'Combat', 'Resilence', 'Strategy', 'Unity', 'Gold'
          ].map((reward) => (
            <label key={reward}>
              Reward {reward} Points:
              <input
                type="number"
                name={`reward${reward}Points`}
                value={formValues[`reward${reward}Points`]}
                onChange={handleChange}
              />
            </label>
          ))}

          <button type="submit">Save Changes</button>
          <button type="button" onClick={() => setEditingTemplate(null)}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default ActivityLevelTemplateTable;
