import React from 'react';
import './ItemCard.css'; // Make sure to create this CSS file for styling

const ItemCard = ({ item, onWear, onUnwear }) => {
    console.log("item", item);
    return (

        <div className="item-card" style={{ borderColor: item.itemTemplate?.gameAttribute?.color }}>
            <img src={`/activity/${item.itemTemplate?.iconName}`} alt={item.itemTemplate?.name} className="item-icon"/>
            <h3>{item.itemTemplate?.name}</h3>
            <p>{item.itemTemplate?.itemWearType?.name}</p>
            <p>Level: {item.itemLevelTemplate?.level}</p>
            <p>Rarity: {item.itemTemplate.itemRarityType.name}</p>
            <p>Damage: {item.damage}</p>
          
            <button onClick={() => item.isWorn ? onUnwear(item.id) : onWear(item.id)}>
                {item.isWorn ? 'Unwear It' : 'Wear It'}
            </button>
        </div>
    );
};

export default ItemCard;