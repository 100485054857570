import React from 'react';
import './QuestionModal.css';

const QuestionModal = ({ currentQuestion, handleQuestionAnswered }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{currentQuestion.questionTitle}</h3>
        <ul>
          {[currentQuestion.answer1, currentQuestion.answer2, currentQuestion.answer3, currentQuestion.answer4].map((answer, index) => (
            <li key={index}>
              <button className="styled-button" onClick={() => handleQuestionAnswered(index + 1)}>
                {answer}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionModal;
