import React from 'react';
import './PointCard.css';

const PointCard = ({ description, points, onIncrease, onDecrease }) => {
    return (
        <div className="point-card">
            <p>{description}</p>
            <div className="points-control">
                <button onClick={onDecrease}>-</button>
                <span>{points}</span>
                <button onClick={onIncrease}>+</button>
            </div>
        </div>
    );
};

export default PointCard;
