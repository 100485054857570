// components/ChallengeTemplateAdmin.js
import React, { useEffect, useState } from 'react';
import ChallengeTemplateTable from './ChallengeTemplateTable';
import ChallengeTemplateCreateForm from './ChallengeTemplateCreateForm';
import axios from 'axios';

const ChallengeTemplateAdmin = () => {
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/admin/allchallengetemplates', { headers });
        setChallenges(response.data);
      } catch (error) {
        console.error('Error fetching challenge templates:', error);
      }
    };

    fetchChallenges();
  }, []);

  const handleChallengeCreated = (newChallenge) => {
    setChallenges([...challenges, newChallenge]);
  };

  return (
    <div>
      <h1>Challenges Dashboard</h1>
      <ChallengeTemplateTable challenges={challenges} setChallenges={setChallenges} />
      <ChallengeTemplateCreateForm onChallengeCreated={handleChallengeCreated} />
    </div>
  );
};

export default ChallengeTemplateAdmin;
