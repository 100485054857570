import React from 'react';
import './VideoInfoModal.css';

const VideoInfoModal = ({ isOpen, videos, orderMax, closeModal }) => {
    if (!isOpen) return null;

    return (
        <div onClick={closeModal}>
            <div className="verification-card-description">
                {videos.map((video) => {
                    // Vypočíta minúty a sekundy
                    const minutes = Math.floor(video.secondLength / 60);
                    const seconds = video.secondLength % 60;

                    // Zistí, či má video byť preskrtnuté
                    const isWatched = video.order <= orderMax;

                    return (
                        <div key={video.id}>
                            <div className={isWatched ? "videoName strikethrough" : "videoName"}>
                                {video.name} ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VideoInfoModal;
