import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MapComponent.css';
import mapboxgl from 'mapbox-gl';
import { FaFilter } from 'react-icons/fa';
import CardMenuButton from '../Planet/CardMenuButton';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from 'react-router-dom';
import VerificationModal from './VerificationModal'; // Import the Modal component
import SpotMarker from './SpotMarker'; 
import SpotPopupWithCombat from './SpotPopUpWithCombat';
import SpotPopupWithoutCombat from './SpotPopUpWithoutCombat';
import { createRoot } from 'react-dom/client';


const MapSpot = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [spots, setSpots] = useState([]);
  const [filteredSpots, setFilteredSpots] = useState([]);
  const [filters, setFilters] = useState({
    gameAttributes: [],
    level: '',
    zone: '',
  });

 console.log(filteredSpots);

  const [isSpotPopupWithoutCombatOpen, setIsSpotPopupWithoutCombatOpen] = useState(false);
  const [isSpotPopupWithCombatOpen, setIsSpotPopupWithCombatOpen] = useState(false);

  const [isSpotVerificationModal, setIsSpotVerificationModal] = useState(false);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [selectedSpotActivity, setSelectedSpotActivity ] = useState(null);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [Key, setKey] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false); // State to toggle filter popup
console.log(isModalOpen);
  const navigate = useNavigate();

  const gameAttributes = [
    { id: 1, status: 1, name: 'Vitalita' },
    { id: 2, status: 1, name: 'Sila' },
    { id: 3, status: 1, name: 'Inteligencia' },
    { id: 4, status: 1, name: 'Výdrž' },
    { id: 5, status: 1, name: 'Súboj' },
    { id: 6, status: 1, name: 'Charizma' },
    { id: 7, status: 1, name: 'Odolnosť' },
    { id: 9, status: 1, name: 'Stratégia' },
  ];

  useEffect(() => {
    // Fetch spots from backend
    const fetchSpots = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in local storage.');
          setVerificationMessage('No token found. Please log in.');
          window.location.href = '/login';
          return;
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/map', { headers });
        console.log("response.data", response.data);
        setSpots(response.data.$values || response.data);
        setFilteredSpots(response.data.$values || response.data);
        initializeMap(response.data.$values || response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          console.error('Error  :', error);
        }
      }
    };

    fetchSpots();
  }, []);

  const initializeMap = (spots) => {
    if (!mapContainerRef.current) return;

    // Ensure the map container is empty
    mapContainerRef.current.innerHTML = '';

    mapboxgl.accessToken = 'pk.eyJ1IjoibWFyb3NraXJhIiwiYSI6ImNsd25zbm5jMjIweW4yaXBmdWd6Z2o1b3cifQ.FGts0gETD3can90rbB6MnA';
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [17.1482, 48.15972], // Initial map center [lng, lat]
      zoom: 12
    });

 


  spots.forEach(spot => {
    const markerDiv = document.createElement('div');
    const root = createRoot(markerDiv);  // Create a root for the React component inside the markerDiv

    // Render the SpotMarker component into the markerDiv
      // Render the SpotMarker component into the markerDiv
  root.render(
    <SpotMarker spot={spot} onClick={() => handleSpotClick(spot)} />
  );


    
  const handleSpotClick = (spot) => {
    setSelectedSpot(spot);
    if (spot.status === 2) {
      setIsSpotVerificationModal(true); // Open a specific modal for status 2
      setKey(spot.gameAttribute.code); // Set the game attribute code
    } else if (spot.status === 1 || spot.status === 5) {
      setIsSpotPopupWithCombatOpen(true);

    } else if (spot.status == 10){
      setIsSpotPopupWithoutCombatOpen(true); // Open the general modal
    }
  };

  /*
const handleSpotClick = (spot) => {
    console.log("Spot clicked:", spot.name);
    setSelectedSpot(spot);
    if (spot.status == 10) {
      setIsSpotVerificationModal(true);
        setKey(spot.gameAttribute.code);
    } else if (spot.status == 1) {
      setIsSpotPopupWithCombatOpen(true);
    
      } else {
        setIsSpotPopupWithoutCombatOpen(true);
    }
};*/


    // Create and add the marker to the map
    new mapboxgl.Marker(markerDiv)
    .setLngLat([spot.lon, spot.lat])
    .addTo(map)
    .getElement()
    .addEventListener('click', () => handleSpotClick(spot));
  });

    mapRef.current = map; // Store the map instance
  };

  const updateMapMarkers = (spots) => {
    if (!mapRef.current) return;

    // Clear existing markers
    const existingMarkers = document.querySelectorAll('.mapboxgl-marker');
    existingMarkers.forEach(marker => marker.remove());

    // Add new markers
    spots.forEach(spot => {
        const markerDiv = document.createElement('div');
        const root = createRoot(markerDiv); // Use React 18's createRoot for concurrent features

        root.render(
            <SpotMarker spot={spot} onClick={() => handleSpotClick(spot)} />
        );

        // Create and add the marker to the map
        new mapboxgl.Marker(markerDiv)
            .setLngLat([spot.lon, spot.lat])
            .addTo(mapRef.current)
            .getElement()
            .addEventListener('click', () => handleSpotClick(spot));
    });
};

const handleSpotClick = (spot) => {
    console.log("Spot clicked:", spot.name);
   /* setSelectedSpot(spot);
    if (spot.status == 10) {
      setIsSpotVerificationModal(true);
        setKey(spot.gameAttribute.code);
    } else if (spot.status == 1) {
      setIsSpotPopupWithCombatOpen(true);
    
      } else {
        setIsSpotPopupWithoutCombatOpen(true);
    }*/
};


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return { ...prevFilters, gameAttributes: [...prevFilters.gameAttributes, name] };
      } else {
        return { ...prevFilters, gameAttributes: prevFilters.gameAttributes.filter(attr => attr !== name) };
      }
    });
  };

  const applyFilters = () => {
    let filtered = spots;
    console.log("filter spots" , spots);
    if (filters.gameAttributes.length > 0) {
      filtered = filtered.filter(spot => filters.gameAttributes.includes(spot.gameAttribute.name));
    }
    if (filters.level) {
      filtered = filtered.filter(spot => spot.level === parseInt(filters.level));
    }
    if (filters.zone) {
      filtered = filtered.filter(spot => spot.zone.name.includes(filters.zone));
    }
    setFilteredSpots(filtered);
    updateMapMarkers(filtered);
    setIsFilterVisible(false); // Close the filter popup after applying filters
  };

  /*const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon1 - lon2) * Math.PI / 180;
    const a =
      0.5 - Math.cos(dLat) / 2 +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      (1 - Math.cos(dLon)) / 2;

    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };*/

  const verifyProximitySpotRequest = () => {
    try {
      console.log("aaa");
      /*const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const distance = calculateDistance(latitude, longitude, selectedSpot.lat, selectedSpot.lon) * 1000; // Convert to meters
      console.log("distance", distance);
      if (distance <= selectedSpot.radius) {
        setVerificationMessage('Áno');
        /*startCombat();*/
        createSpotRequest();

/*
      } else {
        setVerificationMessage('Nie');
      }*/
    } catch (error) {
      console.error('Problém pri získavaní polohy:', error);
      setVerificationMessage('Nie je možné lokalizovať polohu.');
    }
  };

  const verifyProximityCombat = async () => {
    try {
      console.log("aaa");
      /*const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const distance = calculateDistance(latitude, longitude, selectedSpot.lat, selectedSpot.lon) * 1000; // Convert to meters
      console.log("distance", distance);
      if (distance <= selectedSpot.radius) {
        setVerificationMessage('Áno');*/
      /*  startCombat();*/
      /*  createSpotRequest();*/

/*
      } else {
        setVerificationMessage('Nie');
      }*/
    } catch (error) {
      console.error('Problém pri získavaní polohy:', error);
      setVerificationMessage('Nie je možné lokalizovať polohu.');
    }
  };

  const verifyKey = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Žiaden token nebol nájdený.');
        setVerificationMessage('Token nebol nájdený. Je potrebné sa prihlásiť.');
        window.location.href = '/login';
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      console.log("Token found: ", token);
      console.log("Request URL: ", `https://dakujemorg-002-site1.ftempurl.com/api/activity/SpotCheck/${selectedSpot.id}/${Key}`);

      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/activity/SpotCheck/${selectedSpot.id}/${Key}`, { headers });
      console.log("Response: ", response);

      const spotSignature = response.data;
      console.log("spotSignature: ", spotSignature);

      if (spotSignature) {
        console.log("Opening modal...");
        setIsModalOpen(true);
      } else {
        setVerificationMessage('Nesprávna kombinácia spotu a kľúča');
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error  spells:', error);
        setVerificationMessage(error.message);
      }
    }
  };

  /*const startCombat = async () => {
    try {
      // Retrieve the token from local storage or any other secure place where you store the token
      const token = localStorage.getItem('token');

      // Set up the headers for the request
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/combat/startCombat/${selectedSpot.id}`, {}, { headers });
      const combat = response.data;
      navigate(`/g/combat/${selectedSpot.id}/${combat.id}`, { state: { combat, selectedSpot } });
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching spells:', error);
      }
    }
  };*/

  const createSpotRequest = async () => {
    try {
      // Retrieve the token from local storage or any other secure place where you store the token
      const token = localStorage.getItem('token');

      // Set up the headers for the request
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/createSpotRequest/${selectedSpot.id}/${selectedSpotActivity.id}`, {}, { headers });
      console.log(response);
      window.location.reload();
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching spells:', error);
      }
    }
  };

  const createVerificationRequest = async (spotId, Key, text, url, videoFile) => {
    try {
      console.log("Vytváranie verifikácie...");
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token nebol nájdený.');
        setVerificationMessage('Token nebol nájdený. Prihlás sa...');
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const body = {
        spotId,
        Key,  // Include Key in the request payload
        text,
        url
      };

      console.log("Request payload:", body);  // Log the request payload

      const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createSpotVerification', body, { headers });
      const verificationRequest = response.data;
      console.log('Verifikácia bola vytvorená: ', verificationRequest);

      if (videoFile) {
        await handleVideoUpload(spotId, verificationRequest.id, videoFile);
      } else {
        setVerificationMessage('Verifikácia bola vytvorená bez videa...');
      }
    } catch (error) {
      console.error('Error pri vytváraní verifikácie:', error);

      // Log detailed error information
      if (error.response && error.response.data && error.response.data.errors) {
        console.error('Validačný error:', error.response.data.errors);
      }

      setVerificationMessage('Error creating verification request.');
    }
  };

  const handleVideoUpload = async (spotId, verificationRequestId, videoFile) => {
    try {
      console.log("Nahrávanie videa...");
      const formData = new FormData();
      formData.append('videoFile', videoFile);

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage.');
        setVerificationMessage('No token found. Please log in.');
        return;
      }

      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/UploadVideo/${spotId}/${verificationRequestId}`, formData, { headers });
      console.log("Video upload response: ", response);
      setVerificationMessage('Video uploaded successfully.');
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error upload  video:', error);
      }
    }
  };
/*
  const currentPosition = {
    lat: 48.15972, // Replace with actual current latitude
    lon: 17.1482   // Replace with actual current longitude
  };
*/


  const toggleFilterPopup = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const [isGameAttributesVisible, setIsGameAttributesVisible] = useState(false);

  return (
    <div className="container-screen">
      <div className="menu">
          <CardMenuButton title="Mapa" onClick={() => navigate('/g/mapSpots')} />
          <CardMenuButton title="Akcie" onClick={() => navigate('/g/events')}  />
          <CardMenuButton title="Online" onClick={() => navigate('/g/onlineEvents')}  />
      </div>
      <FaFilter className="filter-icon" onClick={toggleFilterPopup} />
      {isFilterVisible && (
        <div className="filter-popup">
          <h3>Filter</h3>
          <div>
            <button onClick={() => setIsGameAttributesVisible(!isGameAttributesVisible)}>
              Atribúty
            </button>
            {isGameAttributesVisible && (
  <div className="checkbox-list">
    {gameAttributes.map(attr => (
      <label key={attr.id}>
        <input
          type="checkbox"
          name={attr.name}
          checked={filters.gameAttributes.includes(attr.name)}
          onChange={handleCheckboxChange}
        />
        {attr.name}
      </label>
    ))}
  </div>
)}
</div>
<label>
 Fáza:
  <input type="text" name="level" value={filters.level} onChange={handleFilterChange} />
</label>
<label>
  Zóna:
  <input type="text" name="zone" value={filters.zone} onChange={handleFilterChange} />
</label>
<button onClick={applyFilters}>Aplikovať výber</button>
<button onClick={toggleFilterPopup}>Zatvoriť</button>
</div>
)}

<div ref={mapContainerRef} className="map-container" />
{/*<SpotPopup
    isOpen={isFilterPopupOpen3} // Pass the state to control visibility
    spot={selectedSpot}
    verificationMessage={verificationMessage}
    Key={Key}
    setKey={setKey}
    verifyProximity={verifyProximity}
    verifyKey={verifyKey}
    onClose={() => setIsFilterPopupOpen2(false)}
/>*/}
<SpotPopupWithCombat
    isOpen={isSpotPopupWithCombatOpen} // Pass the state to control visibility
    spot={selectedSpot}
    verificationMessage={verificationMessage}
    Key={Key}
    setKey={setKey}
    setSelectedSpotActivity = {setSelectedSpotActivity}
    verifyProximityCombat={verifyProximityCombat}
    verifyKey={verifyKey}
    onClose={() => setIsSpotPopupWithCombatOpen(false)}
/>

<SpotPopupWithoutCombat
    isOpen={isSpotPopupWithoutCombatOpen} // Pass the state to control visibility
    spot={selectedSpot}
    verificationMessage={verificationMessage}
    Key={Key}
    setKey={setKey}
    setSelectedSpotActivity = {setSelectedSpotActivity}
    verifyProximitySpotRequest={verifyProximitySpotRequest}
    verifyKey={verifyKey}
    onClose={() => setIsSpotPopupWithoutCombatOpen(false)}
/>
<VerificationModal
  isOpen={isSpotVerificationModal}
  onClose={() => setIsSpotVerificationModal(false)}
  spot={selectedSpot}
  Key={Key}  // Pass Key to the VerificationModal component
  onCreateVerificationRequest={createVerificationRequest}
  handleVideoUpload={handleVideoUpload} 

/>
{/*<h2>Spots within 2km</h2>
<table className="tableMapSpot">
  <thead>
    <tr>
      <th>Name</th>
      <th>Zone</th>
      <th>Distance (km)</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredSpots.filter(spot => calculateDistance(currentPosition.lat, currentPosition.lon, spot.lat, spot.lon) <= 20).map((spot) => (
      <tr key={spot.id}>
        <td>{spot.name}</td>
        <td>{spot.zone?.name}</td>
        <td>{calculateDistance(currentPosition.lat, currentPosition.lon, spot.lat, spot.lon).toFixed(2)}</td>
        <td><button onClick={() => openFilterPopup(spot)}>Details</button></td>
      </tr>
    ))}
  </tbody>
</table> */}
</div>
);
};

export default MapSpot;