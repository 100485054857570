import React, { useState } from 'react';
import './ResearchStackVerificationModal.css';

const ResearchStackVerificationModal = ({ isOpen, onClose, research, onCreateResearchVerificationRequest, researchKey, style, styleOverlay }) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  if (!isOpen) return null;
 console.log("researchVerification", research);
  const handleVideoUpload = async () => {
    if (videoFile) {
      setUploading(true);
      setUploadMessage('');
      try {
        await onCreateResearchVerificationRequest(research.researchId, researchKey, text, url, videoFile);
        setUploadMessage('Video uploaded successfully.');
      } catch (error) {
        setUploadMessage('Error uploading video.');
      } finally {
        setUploading(false);
      }
    } else {
      setUploadMessage('Please select a video file.');
    }
  };

  const handleCreateResearchVerificationRequest = async () => {
    
        await onCreateResearchVerificationRequest(research.researchId, researchKey, text, url, videoFile);

      
  };

  return (
    <div className="research-verification-modal-overlay" style={styleOverlay}>
      <div className="research-verification-modal-content" style={style}>
        <h3>{research.name}</h3>
        <p><strong>Generated Key:</strong> {researchKey}</p>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text"
        />
        <input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter URL"
        />
        <input
          type="file"
          onChange={(e) => setVideoFile(e.target.files[0])}
          accept="video/*"
        />
        <button onClick={handleVideoUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Video'}
        </button>
        {uploadMessage && <p>{uploadMessage}</p>}
        <button className="styled-button" onClick={handleCreateResearchVerificationRequest}>Poslať verifikaciu bez videa</button>
        <button className="styled-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ResearchStackVerificationModal;
