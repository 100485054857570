// components/SpotEditModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root'); // Adjust if your root element has a different id

const SpotEditModal = ({ isOpen, onClose, spot, onUpdate }) => {
  const [formData, setFormData] = useState({
    name: spot.name || '',
    spotId: spot.id || '',
    description: spot.description || '',
    status: spot.status || 0,
    level: spot.level || 0,
    gameAttributeId: spot.gameAttributeId || 0,
    lat: spot.lat || 0,
    lon: spot.lon || 0,
    radius: spot.radius || 0,
    frequencyId: spot.frequencyId || 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      await axios.put(`https://dakujemorg-002-site1.ftempurl.com/api/admin/updateSpot/`, formData, { headers });
      onUpdate(); // Call the onUpdate prop to refresh the spot details
      onClose(); // Close the modal after updating
    } catch (error) {
      console.error('Failed to update spot:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Edit Spot">
      <h2>Edit Spot Details</h2>
      <form onSubmit={handleSubmit}>
        <label>Name:</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />
        
        <label>Description:</label>
        <input type="text" name="description" value={formData.description} onChange={handleChange} />

        <label>Status:</label>
        <input type="number" name="status" value={formData.status} onChange={handleChange} />

        <label>Level:</label>
        <input type="number" name="level" value={formData.level} onChange={handleChange} />

        <label>Game Attribute ID:</label>
        <input type="number" name="gameAttributeId" value={formData.gameAttributeId} onChange={handleChange} />

        <label>Latitude:</label>
        <input type="number" name="lat" value={formData.lat} onChange={handleChange} />

        <label>Longitude:</label>
        <input type="number" name="lon" value={formData.lon} onChange={handleChange} />

        <label>Radius:</label>
        <input type="number" name="radius" value={formData.radius} onChange={handleChange} />

        <label>Frequency ID:</label>
        <input type="number" name="frequencyId" value={formData.frequencyId} onChange={handleChange} />

        <button type="submit">Save Changes</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </Modal>
  );
};

export default SpotEditModal;
