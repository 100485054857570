// components/ItemRarityTemplateTable.js
import React from 'react';

const ItemRarityTemplateTable = ({ itemRarityTemplates }) => {
  return (
    <div>
      <h3>Item Rarity Templates</h3>
      <table>
        <thead>
          <tr>
            <th>Rarity</th>
            <th>Status</th>
            <th>Note</th>
            <th>Durability</th>
            <th>PAI</th>
            <th>PAC</th>
            <th>PAS</th>
            <th>MAI</th>
            <th>MAC</th>
            <th>MAS</th>
            <th>Special Attribute</th>
            <th>Strength</th>
            <th>Vitality</th>
            <th>Inteligence</th>
            <th>Charism</th>
            <th>Stamina</th>
            <th>Combat</th>
            <th>Resilence</th>
            <th>Strategy</th>
            <th>Unity</th>
            <th>Special Troop</th>
            <th>Troop Template</th>
          </tr>
        </thead>
        <tbody>
          {itemRarityTemplates.map((template) => (
            <tr key={template.id}>
              <td>{template.rarity?.name}</td>
              <td>{template.status}</td>
              <td>{template.levelNote}</td>
              <td>{template.maxDurability}</td>
              <td>{template.physicalAttackInfantryIncrease}</td>
              <td>{template.physicalAttackCavalryIncrease}</td>
              <td>{template.physicalAttackSiegeIncrease}</td>
              <td>{template.magicalAttackInfantryIncrease}</td>
              <td>{template.magicalAttackCavalryIncrease}</td>
              <td>{template.magicalAttackSiegeIncrease}</td>
              <td>{template.effectiveOnSpecialAttribute ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnStrength ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnVitality ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnInteligence ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnCharism ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnStamina ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnCombat ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnResilence ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnStrategy ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnUnity ? 'Yes' : 'No'}</td>
              <td>{template.effectiveOnSpecialTroop ? 'Yes' : 'No'}</td>
              <td>{template.troopTemplate?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemRarityTemplateTable;
