// components/ItemTemplateCreateForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemTemplateCreateForm = ({ onItemCreated }) => {
  const [item, setItem] = useState({
    gameAttributeId: 0,
    itemWearTypeId: '',
    level: 0,
    name: '',
    description: '',
    iconName: '',
  });

  const [gameAttributes, setGameAttributes] = useState([]);
  const [itemWearTypes, setItemWearTypes] = useState([]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response1 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/GameAttributeDomain`);
        setGameAttributes(response1.data);

        const response2 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/ItemWearTypeDomain`);
        setItemWearTypes(response2.data);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };

    fetchDomains();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createItemTemplate`, item);
      alert('Item Template created successfully!');
      onItemCreated(response.data); // Notify parent component
      setItem({
        gameAttributeId: 0,
        itemWearTypeId: '',
        level: 0,
        name: '',
        description: '',
        iconName: '',
      });
    } catch (error) {
      alert('Failed to create item template');
      console.error('Error creating item template:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="name" value={item.name} onChange={handleChange} />
      </label>
      <label>
        Description:
        <input type="text" name="description" value={item.description} onChange={handleChange} />
      </label>
      <label>
        Game Attribute:
        <select name="gameAttributeId" value={item.gameAttributeId} onChange={handleChange}>
          <option value="">Select Game Attribute</option>
          {gameAttributes.map((attr) => (
            <option key={attr.id} value={attr.id}>
              {attr.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Item Wear Type:
        <select name="itemWearTypeId" value={item.itemWearTypeId} onChange={handleChange}>
          <option value="">Select Item Wear Type</option>
          {itemWearTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Level:
        <input type="number" name="level" value={item.level} onChange={handleChange} />
      </label>
      <label>
        Icon Name:
        <input type="text" name="iconName" value={item.iconName} onChange={handleChange} />
      </label>
      <button type="submit">Create Item Template</button>
    </form>
  );
};

export default ItemTemplateCreateForm;
