// components/ItemRarityTemplateCreateForm.js
import React, { useState } from 'react';
import axios from 'axios';

const ItemRarityTemplateCreateForm = ({ itemTemplateId, onTemplateCreated }) => {
  const [formValues, setFormValues] = useState({
    itemTemplateId: itemTemplateId,
    rarityId: 0,
    status: 0,
    levelNote: '',
    maxDurability: 0,
    physicalAttackInfantryIncrease: 0,
    physicalAttackCavalryIncrease: 0,
    physicalAttackSiegeIncrease: 0,
    magicalAttackInfantryIncrease: 0,
    magicalAttackCavalryIncrease: 0,
    magicalAttackSiegeIncrease: 0,
    effectiveOnSpecialAttribute: false,
    effectiveOnStrength: false,
    effectiveOnVitality: false,
    effectiveOnInteligence: false,
    effectiveOnCharism: false,
    effectiveOnStamina: false,
    effectiveOnCombat: false,
    effectiveOnResilence: false,
    effectiveOnStrategy: false,
    effectiveOnUnity: false,
    effectiveOnSpecialTroop: false,
    troopTemplateId: null,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createItemRarityTemplate/${itemTemplateId}`, formValues);
      alert('Item Rarity Template created successfully!');
      onTemplateCreated(response.data); // Notify the parent component of the new template
      setFormValues({
        ...formValues,
        rarityId: 0,
        levelNote: '',
        maxDurability: 0,
        physicalAttackInfantryIncrease: 0,
        physicalAttackCavalryIncrease: 0,
        physicalAttackSiegeIncrease: 0,
        magicalAttackInfantryIncrease: 0,
        magicalAttackCavalryIncrease: 0,
        magicalAttackSiegeIncrease: 0,
        effectiveOnSpecialAttribute: false,
        effectiveOnStrength: false,
        effectiveOnVitality: false,
        effectiveOnInteligence: false,
        effectiveOnCharism: false,
        effectiveOnStamina: false,
        effectiveOnCombat: false,
        effectiveOnResilence: false,
        effectiveOnStrategy: false,
        effectiveOnUnity: false,
        effectiveOnSpecialTroop: false,
        troopTemplateId: '',
      });
    } catch (error) {
      alert('Failed to create item rarity template');
      console.error('Error creating item rarity template:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Rarity ID:
        <input type="number" name="rarityId" value={formValues.rarityId} onChange={handleChange} />
      </label>
      <label>
        Level Note:
        <input type="text" name="levelNote" value={formValues.levelNote} onChange={handleChange} />
      </label>
      <label>
        Max Durability:
        <input type="number" name="maxDurability" value={formValues.maxDurability} onChange={handleChange} />
      </label>

      {/* Physical Attack Increase Fields */}
      <h3>Physical Attack Increase</h3>
      <label>
        Infantry:
        <input type="number" name="physicalAttackInfantryIncrease" value={formValues.physicalAttackInfantryIncrease} onChange={handleChange} />
      </label>
      <label>
        Cavalry:
        <input type="number" name="physicalAttackCavalryIncrease" value={formValues.physicalAttackCavalryIncrease} onChange={handleChange} />
      </label>
      <label>
        Siege:
        <input type="number" name="physicalAttackSiegeIncrease" value={formValues.physicalAttackSiegeIncrease} onChange={handleChange} />
      </label>

      {/* Magical Attack Increase Fields */}
      <h3>Magical Attack Increase</h3>
      <label>
        Infantry:
        <input type="number" name="magicalAttackInfantryIncrease" value={formValues.magicalAttackInfantryIncrease} onChange={handleChange} />
      </label>
      <label>
        Cavalry:
        <input type="number" name="magicalAttackCavalryIncrease" value={formValues.magicalAttackCavalryIncrease} onChange={handleChange} />
      </label>
      <label>
        Siege:
        <input type="number" name="magicalAttackSiegeIncrease" value={formValues.magicalAttackSiegeIncrease} onChange={handleChange} />
      </label>

      {/* Effective On Attributes */}
      <h3>Effective On Attributes</h3>
      {[
        'SpecialAttribute', 'Strength', 'Vitality', 'Inteligence', 'Charism', 
        'Stamina', 'Combat', 'Resilence', 'Strategy', 'Unity', 'SpecialTroop'
      ].map((attr) => (
        <label key={attr}>
          Effective on {attr}:
          <input type="checkbox" name={`effectiveOn${attr}`} checked={formValues[`effectiveOn${attr}`]} onChange={handleChange} />
        </label>
      ))}

      <label>
        Troop Template ID:
        <input type="text" name="troopTemplateId" value={formValues.troopTemplateId} onChange={handleChange} />
      </label>

      <button type="submit">Create Item Rarity Template</button>
    </form>
  );
};

export default ItemRarityTemplateCreateForm;
