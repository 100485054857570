import React, { useMemo, useState } from 'react';
import './ResearchCardToLearn.css';
import { gameAttributes, idToKeyMap} from '../globalParameters.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import VideoInfoModal from './VideoInfoModal.js';




const ResearchCardToLearn = ({ research, onClickOpenModal }) => {


  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    setModalOpen(true);
};

  const handleCloseModal = (event) => {
    event.stopPropagation();
      setModalOpen(false);
  };

 // Vypočíta počet videí
const videoCount = research.researchVideos.length;

// Vypočíta celkový počet sekúnd všetkých videí
const fullLength = research.researchVideos.reduce((total, video) => total + video.secondLength, 0);
const fullMinutes = Math.floor(fullLength / 60);
const videoInfo =  `${fullMinutes}' (${videoCount})`;

  const cardStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.6), ${attribute.rgbColorFirst}, 0.6))`

    return {
      background: background,
      border: `2px solid ${attribute.hexColorSecond}`,
      backgroundColor: `${attribute.hexColorBackground}`,
      color: '#fff' // Biela textová farba pre lepšiu čitateľnosť
    };
  }, [research.gameAttribute.id]);

  const troopIconStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.3))`

    return {
      background: background,
    };
  }, [research.gameAttribute.id]);

  console.log("research to learn", research);

   
  const handleClick = () => {
     onClickOpenModal(research);
  };
  return (
    <>
    <div className={`research-card`} id={'research-'+research.id} style={cardStyle} onClick={handleClick}>
    <div className="research-card-header">
    <div className="research-icon-container">
              <img
                  src={`/activity/${research.iconName}`}
                  className="troop-icon"
                  style={troopIconStyle}
              />
          </div>
      <div className="research-name">
        {research.name}
      </div>
      <div className="research-videoinfo" onClick={(event) => handleOpenModal(event)}>
           {videoInfo}
      </div>
    </div>
    <VideoInfoModal isOpen={isModalOpen} videos={research.researchVideos} orderMax = {0} closeModal={(event) => handleCloseModal(event)} />
    <div className="research-description">
      {research.description}
    </div>
    <div className="research-footer">
      <div className="research-level">
        { 1}
      </div>
      <div className="research-icons">
        <FontAwesomeIcon icon={faCoins} /> <span>{research.cost}</span>
      </div>
    </div>
   
  </div>

   </>
  );
};

export default React.memo(ResearchCardToLearn);
