import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './VerificationRequestsScreen.css';
import CardMenuButton from '../Planet/CardMenuButton';
import VerificationDetailModal from './VerificationDetailModal'; // New modal component
import CreateVerdictModal from './CreateVerdictModal'; // New modal component
import ResearchVerificationCard from './ResearchVerificationCard';
import SpotVerificationCard from './SpotVerificationCard';
import { useNavigate } from 'react-router-dom';

const VerificationRequestsScreen = () => {
  const [spotVerificationRequests, setSpotVerificationRequests] = useState([]);
  const [researchVerificationRequests, setResearchVerificationRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isVerdictModalOpen, setIsVerdictModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const scrollRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchVerificationRequests = async () => {
      try {
        const token = localStorage.getItem('token');

        console.log(error);
        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/verificationRequests', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
console.log("requests", response.data);
        const requests = response.data.$values || response.data.result;

const spotRequests = requests.filter(request => request.spotId != null);
const researchRequests = requests.filter(request => request.researchId != null);
console.log("requests", requests);
console.log("spotRequests", spotRequests);
console.log("researchRequests", researchRequests);
        setSpotVerificationRequests(spotRequests);
        setResearchVerificationRequests(researchRequests);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          console.error('Failed fetching verification requests:', error);
          alert('Failed fetching verification requests:'); 
        }
      }
    };

    fetchVerificationRequests();
  }, []);




  const handleRequestClick = (request) => {
    const element = document.getElementById(`research-${request.id}`);
    if (!element) {
        console.error('Element not found');
        return;
    }
   
    const rect = element.getBoundingClientRect();

    const absolutePositionY = rect.top + scrollRef.current.scrollTop;

    setModalPosition({ x: rect.left, y: absolutePositionY });
    setSelectedRequest(request);
    setIsDetailModalOpen(true);
  };

  const handleFetchRandomVerification = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/getRandomVerification', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      console.log("response.data.result", response.data.result);
      setSelectedRequest(response.data.result);
      setIsVerdictModalOpen(true);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error fetching random verification:', error);
      setError(error.message);
      }
    }
  };

  const handleCreateVerdict = async (verdict) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createVerdict', verdict, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      setIsVerdictModalOpen(false);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error creating  verdict:', error);
      setError(error.message);
      }
    }
  };


  return (
    <div className="container-screen" ref={scrollRef}>
      <div className="menu">
        <CardMenuButton title="Trhovisko" /*onClick={() => navigate("/g/")}*/ />
        <CardMenuButton title="Tabuľa" /*onClick={() => window.location.href = '/g/spell'}*/ />
        <CardMenuButton title="Verifikácie" onClick={() => navigate("/g/verifications")} />
      </div>
      <button className="button fetch-button" onClick={handleFetchRandomVerification}>Fetch Random Verification</button>


   
      <div className="research-cards-container">
      {researchVerificationRequests.map((request) => (
          <ResearchVerificationCard 
             key={request.id} 
             id={`research-${request.id}`} 
             request={request}
             onOpenModal={() => handleRequestClick(request)}
             showCount  
             />
        ))}
        {spotVerificationRequests.map((request) => (
          <SpotVerificationCard 
             key={request.id} 
             id={`research-${request.id}`} 
             request={request}
             onOpenModal={() => handleRequestClick(request)}
             showCount  
             />
        ))}


        </div>
      {selectedRequest &&  isDetailModalOpen && (
        <VerificationDetailModal
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailModalOpen(false)}
          request={selectedRequest}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left:'50%',
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}

      {selectedRequest &&  isVerdictModalOpen &&(
        <CreateVerdictModal
          isOpen={isVerdictModalOpen}
          onClose={() => setIsVerdictModalOpen(false)}
          request={selectedRequest}
          onCreateVerdict={handleCreateVerdict}
        />
      )}
    </div>
  );
};

export default VerificationRequestsScreen;
