import React from 'react';
import './SpotActivitiesTable.css';

// SpotActivitiesTable Component
const SpotEnemyTroopsActualTable = ({ troops }) => {
  return (
    <table className="spot-activities-table">
      <thead>
        <tr>
          <th>Troop Name</th>
          <th>Rarity</th>
          <th>Count</th>
         
        </tr>
      </thead>
      <tbody>
        {troops.map(troop => (
          <tr key={troop.id}>
            <td>{troop.troopTemplate.name}</td>
            <td>{troop.rarity.name}</td>
            <td>{troop.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpotEnemyTroopsActualTable;
