import React, {useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar.js'; // Ensure this component is implemented
import axios from 'axios';
import './ResearchCardLearning.css';
import { gameAttributes, idToKeyMap} from '../globalParameters.js';
import VideoInfoModal from './VideoInfoModal.js';

const ResearchCardLearning = ({ research }) => {

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    setModalOpen(true);
};

  const handleCloseModal = (event) => {
    event.stopPropagation();
      setModalOpen(false);
  };


  const navigate = useNavigate();
console.log("research pri learning", research);
   // Fetch next video for the research to learn
   const fetchNextVideo = async () => {
    try {
      const token = localStorage.getItem('token'); // Ensure token is managed properly for authentication
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/research/nextVideoToResearchLearn/${research.researchTemplateId}`, { headers });
      if (response.status === 200 && response.data) {
        navigate(`/g/video/${response.data.id}`); // Assuming `/g/video/:videoId` is the route for YouTubeWithInteractions
      } else {
        console.error('Failed to fetch next video');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.message); // Use a more user-friendly notification system if possible
      }
      else if(error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching next video:', error);
      }
    }
  };




  


  const handleClick = () => {
    fetchNextVideo();
  };

  const videoCount = research.researchVideos.length;

// Vypočíta celkový počet sekúnd všetkých videí
const fullLength = research.researchVideos.reduce((total, video) => total + video.secondLength, 0);
const fullMinutes = Math.floor(fullLength / 60);
const videoInfo =  `${fullMinutes}' (${videoCount})`;


  const progress = research.videosWatched / research.totalVideos * 100;


  const cardStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.6), ${attribute.rgbColorFirst}, 0.6))`

    return {
      background: background,
      border: `2px solid ${attribute.hexColorSecond}`,
      backgroundColor: `${attribute.hexColorBackground}`,
      color: '#fff' // Biela textová farba pre lepšiu čitateľnosť
    };
  }, [research.gameAttribute.id]);

  const troopIconStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[research.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.3))`

    return {
      background: background,
    };
  }, [research.gameAttribute.id]);
  console.log("researchLearning", research);

  return (
    <div className={`research-card`} onClick={handleClick} style = {cardStyle}>
      <div className="research-card-header">
      <div className="research-icon-container">
              <img
                  src={`/activity/${research.iconName}`}
                  className="troop-icon"
                  style={troopIconStyle}
              />
          </div>
        <div className="research-name">{research.name}</div>

        <div className="research-videoinfo" onClick={(event) => handleOpenModal(event)}>
           {videoInfo}
      </div>

      </div>
      <VideoInfoModal isOpen={isModalOpen} videos={research.researchVideos} orderMax={research.highestOrderCompleted} closeModal={(event) => handleCloseModal(event)} />
      <ProgressBar progress={progress} />
      <div className="research-description">{research.description}</div>
      <div className="research-footer">
        <div className="research-improvements">
          {/* List improvements if any */}
        </div>
        <div className="research-level">{`Level ${research.level}`}</div>
      </div>
    </div>
  );
};

export default ResearchCardLearning;
