// components/ItemTemplateDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ItemRarityTemplateTable from './ItemRarityTemplateTable';
import ItemRarityTemplateCreateForm from './ItemRarityTemplateCreateForm';

const ItemTemplateDetail = () => {
  const { itemTemplateId } = useParams();
  const [itemTemplate, setItemTemplate] = useState(null);
  const [itemRarityTemplates, setItemRarityTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItemTemplateDetails = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/getItemTemplate/${itemTemplateId}`);
        setItemTemplate(response.data);
      } catch (error) {
        console.error('Error fetching item template details:', error);
      }
    };

    const fetchItemRarityTemplates = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/getItemRarityTemplates/${itemTemplateId}`);
        setItemRarityTemplates(response.data);
      } catch (error) {
        console.error('Error fetching item rarity templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItemTemplateDetails();
    fetchItemRarityTemplates();
  }, [itemTemplateId]);

  const handleNewTemplateCreated = (newTemplate) => {
    setItemRarityTemplates([...itemRarityTemplates, newTemplate]);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Item Template Details</h2>
      {itemTemplate && (
        <div>
          <p><strong>Name:</strong> {itemTemplate.name}</p>
          <p><strong>Description:</strong> {itemTemplate.description}</p>
          <p><strong>Game Attribute ID:</strong> {itemTemplate.gameAttributeId}</p>
          <p><strong>Item Wear Type ID:</strong> {itemTemplate.itemWearTypeId}</p>
          <p><strong>Level:</strong> {itemTemplate.level}</p>
          <p><strong>Icon Name:</strong> {itemTemplate.iconName}</p>
        </div>
      )}

      <h3>Item Rarity Templates</h3>
      <ItemRarityTemplateTable itemRarityTemplates={itemRarityTemplates} />

      <h3>Create New Item Rarity Template</h3>
      <ItemRarityTemplateCreateForm 
        itemTemplateId={itemTemplateId} 
        onTemplateCreated={handleNewTemplateCreated} 
      />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default ItemTemplateDetail;
