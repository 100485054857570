import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ResearchToLearn.css'; 
import CardMenuButton from '../Planet/CardMenuButton';
import ResearchCardToLearn from './ResearchCardToLearn.js';


import {  useNavigate } from 'react-router-dom';
import BuyResearchModal from './BuyResearchModal.js';


const ResearchToLearn = () => {
  const [researchs, setResearchs] = useState([]);
  const [error, setError] = useState(null);
  const [isBuyResearchModalOpen, setIsBuyResearchModalOpen] = useState (false);
  const [selectedResearch, setSelectedResearch] = useState (null);

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const scrollRef = useRef(null);

  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchResearchs = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/research/researchToLearn', { headers });
        console.log(response.data);
        setResearchs(response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error fetching researchs:', error);
        setError(error.message);
        }
      }
    };

    fetchResearchs();
  }, []);

  

  if (error) {
    return <div>Error: {error}</div>;
  }

    // Initiate learning a new research and fetch the next video
    const startLearningNewResearch = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('Nie ste prihlásený.');
          return;
        }
  
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/research/startLearningNewResearch/${selectedResearch.id}`, { headers });
        
        if (response.status === 200 && response.data) {
          navigate(`/g/video/${response.data.id}`);
        } else {
          console.error('Failed to initiate learning new research or to fetch video');
        }
      } catch (error) {
        console.error('Error while initiating new research learning:', error);
        if (error.response && error.response.status === 400 && error.response.data === "Nedostatok zlata") {
          setIsBuyResearchModalOpen(false);
          alert('Nedostatok zlata');
          // Zobrazenie upozornenia prehliadača
        }
        else if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          setIsBuyResearchModalOpen(false);
          alert('Nastala chyba pri načítaní nového výskumu.'); // Všeobecné chybové upozornenie
        }
      }
    };
    const openBuyResearchModal = (research) => {
      const element = document.getElementById(`research-${research.id}`);
      if (!element) {
          console.error('Element not found');
          return;
      }
     
      const rect = element.getBoundingClientRect();
  
      const absolutePositionY = rect.top + scrollRef.current.scrollTop;
  

      setModalPosition({ x: rect.left, y: absolutePositionY });

      console.log("researchOpenModal", research);
      setSelectedResearch(research);
      setIsBuyResearchModalOpen(true);
    };
    
  return (
    <div className="container-screen" ref={scrollRef}>
          <div className="menu">
          <CardMenuButton title="Jednotky" onClick={() => navigate('/g/researchLearned')} />
        <CardMenuButton title="Výskum" onClick={() => navigate('/g/researchLearning')}  />
        <CardMenuButton title="Knižnica" onClick={() => navigate('/g/researchToLearn')}  />
      </div>
      <div className="research-cards-container">
      {researchs.map((research) => (
          research && <ResearchCardToLearn key={research.id} research={research} onClickOpenModal={openBuyResearchModal} />
        ))}
  {isBuyResearchModalOpen && (
        <BuyResearchModal
          isOpen={isBuyResearchModalOpen}
          onClose={() => setIsBuyResearchModalOpen(false)}
          onConfirm={startLearningNewResearch}
          research = {selectedResearch}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left: '50%', 
            width:'80%',
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}

      </div>
    </div>
  );
};

export default ResearchToLearn;
