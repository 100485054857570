import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TroopCreateForm = ({ onTroopCreated }) => {
  const [troop, setTroop] = useState({
    isEnemy: false,
    gameAttributeId: 0,
    name: '',
    troopTypeId: '',
    troopAttackTypeId: '',
    consumption: 0,
    iconName: '',
  });

  const [gameAttributes, setGameAttributes] = useState([]);
  const [troopAttackTypes, setTroopAttackTypes] = useState([]);
  const [troopTypes, setTroopTypes] = useState([]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response1 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/GameAttributeDomain`);
        setGameAttributes(response1.data);

        const response2 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/TroopAttackTypeDomain`);
        setTroopAttackTypes(response2.data);

        const response3 = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/TroopTypeDomain`);
        setTroopTypes(response3.data);
      } catch (error) {
        console.error('Error fetching domain data:', error);
      }
    };

    fetchDomains();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTroop({
      ...troop,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createTroop`, troop);
      alert('Troop created successfully!');
      onTroopCreated(response.data); // Optional callback to inform the parent component
      setTroop({
        isEnemy: false,
        gameAttributeId: 0,
        name: '',
        troopTypeId: '',
        troopAttackTypeId: '',
        consumption: 0,
        iconName:'',
      });
    } catch (error) {
      alert('Failed to create troop');
      console.error('Error creating troop:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={troop.name}
          onChange={handleChange}
          placeholder="Name"
        />
      </label>

      <label>
        Is Enemy:
        <input
          type="checkbox"
          name="isEnemy"
          checked={troop.isEnemy}
          onChange={handleChange}
        />
      </label>

      <label>
        Game Attribute:
        <select
          name="gameAttributeId"
          value={troop.gameAttributeId}
          onChange={handleChange}
        >
          <option value="">Select Game Attribute</option>
          {gameAttributes.map((attr) => (
            <option key={attr.id} value={attr.id}>
              {attr.name}
            </option>
          ))}
        </select>
      </label>

      <label>
        Troop Type:
        <select
          name="troopTypeId"
          value={troop.troopTypeId}
          onChange={handleChange}
        >
          <option value="">Select Troop Type</option>
          {troopTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </label>

      <label>
        Troop Attack Type:
        <select
          name="troopAttackTypeId"
          value={troop.troopAttackTypeId}
          onChange={handleChange}
        >
          <option value="">Select Troop Attack Type</option>
          {troopAttackTypes.map((attackType) => (
            <option key={attackType.id} value={attackType.id}>
              {attackType.typeName}
            </option>
          ))}
        </select>
      </label>

      <label>
        Consumption:
        <input
          type="number"
          name="consumption"
          value={troop.consumption}
          onChange={handleChange}
          placeholder="Consumption"
        />
      </label>

      <label>
        Icon name:
        <input
          type="text"
          name="iconName"
          value={troop.iconName}
          onChange={handleChange}
          placeholder="Icon Name"
        />
      </label>

      <button type="submit">Create TroopTemplate</button>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </form>
  );
};

export default TroopCreateForm;
