import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import axios from 'axios';
import beepSound from './beep.mp3';
import './VideoWithBeep.css';
import QuestionModal from './QuestionModal';

function useOrientation() {
  const [orientation, setOrientation] = useState(window.screen.orientation?.type);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return orientation;
}

const YouTubeWithInteractions = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [videoDetails, setVideoDetails] = useState(null);
  const [isBeeped, setIsBeeped] = useState(false);
  const [clickedAfterPrompt, setClickedAfterPrompt] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  //const [totalWatchTime, setTotalWatchTime] = useState(0);
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [answerTimeout, setAnswerTimeout] = useState(null);
  const [beepTimeout, setBeepTimeout] = useState(null);
  const maxWatchedTimeRef = useRef(0);
  const seekingRef = useRef(false);

  const beepAudio = useRef(new Audio(beepSound));
  const playerRef = useRef(null);
  const watchTimeInterval = useRef(null);
  const beepCheckInterval = useRef(null);

  const orientation = useOrientation();

  useEffect(() => {
    fetchVideoDetails();
    return () => {
      clearInterval(watchTimeInterval.current);
      clearInterval(beepCheckInterval.current);
      clearTimeout(beepTimeout);
      clearTimeout(answerTimeout);
    };
  }, [videoId]);

  const fetchVideoDetails = async () => {
    try {
      const token = localStorage.getItem('token'); // Ensure token is managed properly for authentication
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/research/researchVideo/${videoId}`, {headers});
      setVideoDetails(response.data);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error fetching video detail:', error);

      }
    }
  };

  const handlePlay = () => {
    startWatchTime();
    startBeepCheck();
  };

  const startWatchTime = () => {
    watchTimeInterval.current = setInterval(() => {
      if (playerRef.current && !seekingRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
     //   setTotalWatchTime(prev => prev + 1);

          if (currentTime > maxWatchedTimeRef.current && currentTime < maxWatchedTimeRef.current + 3) {
            maxWatchedTimeRef.current = currentTime;
          } else if (currentTime > maxWatchedTimeRef.current + 1) {
            seekingRef.current = true;
            playerRef.current.seekTo(maxWatchedTimeRef.current);
            setTimeout(() => {
              seekingRef.current = false;
            }, 500); // Adding a small delay to debounce the seeking
          }

        checkForQuestion();
      }
    }, 1000);
  };

  const startBeepCheck = () => {
    beepCheckInterval.current = setInterval(() => {
      setClickedAfterPrompt(false);
      if (Math.random() < 0.2) {
        beepAudio.current.play();
        setIsBeeped(true);
        setBeepTimeout(setTimeout(() => {
          if (!clickedAfterPrompt) {
            reportMistake(0);
            sessionStorage.setItem('notification', 'You did not click the beep button. Please try again tomorrow.');
            navigate('/g/researchLearning');
          }
        }, 4000));
      }
    }, 3000);
  };

  const checkForQuestion = () => {
    const currentTime = playerRef.current.getCurrentTime();
    const upcomingQuestion = videoDetails?.spellVideoQuestions?.find(q => Math.floor(currentTime) === q.secondsFromStart);
    if (upcomingQuestion) {
      setCurrentQuestion(upcomingQuestion);
      setShowQuestion(true);
      playerRef.current.pauseVideo();
      setAnswerTimeout(setTimeout(() => {
        reportMistake(0);
        sessionStorage.setItem('notification', 'You did not answer the question in time. Please try again tomorrow.');
        navigate('/g/researchLearning');
      }, 10000));
    }
  };

  const handleQuestionAnswered = (answer) => {
    clearTimeout(answerTimeout);
    if (answer === currentQuestion.correctAnswer) {
      setQuestionsAnswered(prev => [...prev, currentQuestion.id]);
      setCurrentQuestion(null);
      setShowQuestion(false);
      playerRef.current.playVideo();
    } else {
      reportMistake(0);
      sessionStorage.setItem('notification', 'You gave a bad answer. Please try again tomorrow.');
      navigate('/g/researchLearning');
    }
  };

  const handleBeepButtonClick = () => {
    setClickedAfterPrompt(true);
    setIsBeeped(false);
    clearTimeout(beepTimeout);
  };

  const reportMistake = async (success) => {
    try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in local storage.');
      return;
    }

    const headers = {
      'Authorization': `Bearer ${token}`
    };

    await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/research/video/${videoId}/${success}`, {}, { headers });
  } catch (error) {
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } else {
    console.error('Error report mistake:', error);

    }
  }
};


  const markVideoAsCompleted = async () => {
    try {

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in local storage.');
      return;
    }

    const headers = {
      'Authorization': `Bearer ${token}`
    };

    await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/research/video/${videoId}/1`, {}, { headers });
  } catch (error) {
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } 
  }
  };

  const checkCompletion = () => {
    console.log("videoWatched", videoWatched);
    console.log(" questionsAnswered.length ",  questionsAnswered.length );
    console.log(" videoDetails.spellVideoQuestions.length ",  videoDetails?.spellVideoQuestions?.length );
    console.log(" clickedAfterPrompt ",  clickedAfterPrompt );
    if (videoWatched && questionsAnswered.length === videoDetails.spellVideoQuestions?.length ) {

      markVideoAsCompleted();
      navigate('/g/researchLearning');
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onStateChange = (event) => {
    switch (event.data) {
      case YouTube.PlayerState.PLAYING:
        handlePlay();
        break;
      case YouTube.PlayerState.PAUSED:
      case YouTube.PlayerState.BUFFERING:
        handlePause();
        break;
      case YouTube.PlayerState.ENDED:
        handleEnd();
        break;
      default:
        break;
    }
  };

  const handlePause = () => {
    clearInterval(watchTimeInterval.current);
    clearInterval(beepCheckInterval.current);
    clearTimeout(beepTimeout);
    clearTimeout(answerTimeout);
  };

  const handleEnd = () => {
    setVideoWatched(true);
    handlePause();
    checkCompletion();
  };

  useEffect(() => {
    checkCompletion();
  }, [videoWatched, questionsAnswered]);

  if (!videoDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="youtube-container">
    {orientation.includes('portrait') ? (
      <div className="landscape-warning">
        Pre optimálny zážitok prosím otočte svoje zariadenie na šírku.
      </div>
    ) : (
      <>
        <YouTube
          videoId={videoDetails.videoId}
          opts={{ playerVars: { autoplay: 1 } }}
        onReady={onReady}
        onStateChange={onStateChange}
          onPlaybackRateChange={(e) => {
            if (e.data > 1) {
            playerRef.current.setPlaybackRate(1); // Prevent changing the playback rate
            }
          }}
          className="youtube-player"
        />
        {isBeeped && (
        <button className="beep-button" onClick={handleBeepButtonClick}>
            Click me after beep
          </button>
        )}
        {showQuestion && currentQuestion && (
          <QuestionModal
            currentQuestion={currentQuestion}
          handleQuestionAnswered={handleQuestionAnswered}
          />
        )}
        {clickedAfterPrompt && <p>Thank you for clicking!</p>}
        {videoWatched && <p>You have watched the full video!</p>}
      </>
    )}
  </div>
);
};
export default YouTubeWithInteractions;
