import React from 'react';
import axios from 'axios';
import './TopPanel.css';

import { IoIosChatbubbles } from "react-icons/io";
import { AiFillNotification, AiOutlineLogout } from "react-icons/ai";
import { RiGlobalLine } from "react-icons/ri";


const TopPanel = ({ activeTab, setActiveTab, setShowHome, setShowMissions, setShowPlay, setShowChat, setShowScoreBoard, setShowSettings }) => {
  const openSettings = () => {
    setActiveTab('settings');
    setShowHome(false); 
    setShowMissions(false);
    setShowPlay(false);
    setShowChat(false);
    setShowScoreBoard(false);
    setShowSettings(true);
  };
console.log("activeTab", activeTab);
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage.');
        window.location.href = '/login';
        return;
      }

      await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/account/logout', {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      localStorage.removeItem('token');
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  return (
    <div className="top-panel">
      <div className="icon-container">
      {/*<HPBar hp={80} maxHp={100} mana={50} maxMana={100} energy={30} maxEnergy={100} />*/ }  
        <RiGlobalLine className={/*activeTab === 'settings' ? 'fa-iconTactive' :*/ 'fa-iconT'} onClick={openSettings}/>
        <IoIosChatbubbles className={/*activeTab === 'settings' ? 'fa-iconTactive' :*/ 'fa-iconT'} onClick={openSettings}/>
        <AiFillNotification className={/*activeTab === 'settings' ? 'fa-iconTactive' :*/ 'fa-iconT'} onClick={openSettings}/>
        <AiOutlineLogout className="fa-iconT" onClick={handleLogout}/> {/* Add the logout icon */}
      </div> 
    </div>
  );
};

export default TopPanel;
