// components/ActivityTemplateDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ActivityLevelTemplateTable from './ActivityLevelTemplateTable';  // Assuming similar structure to ItemRarityTemplateTable
import ActivityLevelTemplateCreateForm from './ActivityLevelTemplateCreateForm';  // Assuming similar structure to ItemRarityTemplateCreateForm

const ActivityTemplateDetail = () => {
  const { activityTemplateId } = useParams();
  const [activityTemplate, setActivityTemplate] = useState(null);
  const [activityLevelTemplates, setActivityLevelTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActivityTemplateDetails = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/getActivityTemplate/${activityTemplateId}`);
        setActivityTemplate(response.data);
      } catch (error) {
        console.error('Error fetching activity template details:', error);
      }
    };

    const fetchActivityLevelTemplates = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/getActivityLevelTemplates/${activityTemplateId}`);
        setActivityLevelTemplates(response.data);
      } catch (error) {
        console.error('Error fetching activity level templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchActivityTemplateDetails();
    fetchActivityLevelTemplates();
  }, [activityTemplateId]);

  const handleNewLevelTemplateCreated = (newLevelTemplate) => {
    setActivityLevelTemplates([...activityLevelTemplates, newLevelTemplate]);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Activity Template Details</h2>
      {activityTemplate && (
        <div>
          <p><strong>Name:</strong> {activityTemplate.name}</p>
          <p><strong>Description:</strong> {activityTemplate.description}</p>
          <p><strong>Game Attribute ID:</strong> {activityTemplate.gameAttributeId}</p>
          <p><strong>Icon Name:</strong> {activityTemplate.iconName}</p>
        </div>
      )}

      <h3>Activity Level Templates</h3>
      <ActivityLevelTemplateTable activityLevelTemplates={activityLevelTemplates} />

      <h3>Create New Activity Level Template</h3>
      <ActivityLevelTemplateCreateForm 
        activityTemplateId={activityTemplateId} 
        onTemplateCreated={handleNewLevelTemplateCreated} 
      />
    </div>
  );
};

export default ActivityTemplateDetail;
