import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css'; // Ensure you have the corresponding CSS file
import BottomNavbar from './Planet/BottomNavBar';
import TopPanel from './Planet/TopPanel';

import IconAnaconda from './Dashboard/Icon/iconAnaconda.png';
import IconBear from './Dashboard/Icon/iconBear.png';
import IconCat from './Dashboard/Icon/iconCat.png';
import IconDeer from './Dashboard/Icon/iconDeer.png';
import IconElephant from './Dashboard/Icon/iconElephant.png';
import IconHedgehog from './Dashboard/Icon/iconHedgehog.png';
import IconJaguar from './Dashboard/Icon/iconJaguar.png';
import IconOwl from './Dashboard/Icon/iconOwl.png';
import IconPanda from './Dashboard/Icon/iconPanda.png';
import IconPenguin from './Dashboard/Icon/iconPenguin.png';
import IconTurtle from './Dashboard/Icon/iconTurtle.png';

const avatarMap = {
  1: IconAnaconda,
  2: IconBear,
  3: IconCat,
  4: IconDeer,
  5: IconElephant,
  6: IconHedgehog,
  7: IconJaguar,
  8: IconOwl,
  9: IconPanda,
  10: IconPenguin,
  11: IconTurtle,
};

const avatarId = 3;

const Layout = () => {
  const [activeTab, setActiveTab] = useState('home');

  const [showHome, setShowHome] = useState(false);
  const [showMissions, setShowMissions] = useState(false);
  const [showPlay, setShowPlay] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showScoreBoard, setShowScoreBoard] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

    
   
    console.log(showHome, showMissions,showPlay, showChat, showScoreBoard, showSettings );
  
  return (
    <div className="container">
     <TopPanel
  className="top-panel"
  activeTab={activeTab}
  setActiveTab={setActiveTab}
  setShowHome={setShowHome}
  setShowMissions={setShowMissions}
  setShowPlay={setShowPlay}
  setShowChat={setShowChat}
  setShowScoreBoard={setShowScoreBoard}
  setShowSettings={setShowSettings}
  avatarId={avatarId}
  avatarMap={avatarMap}
/>
      <div className="content">
        <Outlet /> {/* This is where nested routes will be rendered */}
      </div>
      <BottomNavbar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setShowHome={setShowHome}
        setShowMissions={setShowMissions}
        setShowPlay={setShowPlay}
        setShowChat={setShowChat}
        setShowScoreBoard={setShowScoreBoard}
        setShowSettings={setShowSettings}
      />
    </div>
  );
};

export default Layout;
