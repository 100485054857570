import React from 'react';
import './SpotMarker.css';

const SpotMarker = ({ spot, onClick }) => {
    if (!spot || !spot.spotActivities || spot.spotActivities.length === 0) {
        return <div className="custom-marker" onClick={() => onClick(spot)}>No Activities</div>;
    }

    const activityIcon = spot.spotActivities[0]?.activityTemplate?.iconName;
    const backgroundImageUrl = activityIcon ? `/activity/${activityIcon}` : '';
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: '80%', 
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: spot.gameAttribute?.colorBackground,
        border: spot.status == 1 ? '2px solid grey' : spot.status == 5 ? '2px solid white' : '0px solid white' ,

    };

    return (
        <div className="custom-marker" style={backgroundStyle} onClick={() => onClick(spot)}>
            {spot.spotActivities.slice(0, 3).map((activity, index) => (
                <div key={index} className="level-indicator" /*style={{ backgroundColor: "yellow", color: "black" }}*/>
                     {activity.activityLevelTemplate.level}
                </div>
            ))}
            { spot.spotEntity && spot.status !== 1 &&  
               <div className="level-indicator-enemy" /*style={{ backgroundColor: "black", color: "white" }}*/>
                   <span> {spot.spotEntity.enemyTemplate.level}</span>  
            
                </div> }
        </div>
    );
};

export default SpotMarker;
