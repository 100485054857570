import React, { useState, useEffect, useRef, useMemo } from 'react';
import './CreateVerdictModal.css';
import VerdictSlider from '../Activity/VerdictSlider'; // Ensure the path is correct
import { gameAttributes, idToKeyMap} from '../globalParameters';
import KeyIconNumbers from './KeyIconNumbers';

const CreateVerdictModal = ({ isOpen, onClose, request, onCreateVerdict }) => {
  const [verdict, setVerdict] = useState({ verdict: 0, text: '', VerificationRequestId: request?.id });
  const modalContentRef = useRef(null); // Ref to track modal content

  // Function to handle click events outside the modal
  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      onClose(); // Close the modal if the click is outside the modal content
    }
  };

  useEffect(() => {
    if (request) {
      setVerdict((prevVerdict) => ({
        ...prevVerdict,
        VerificationRequestId: request.id,
      }));
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [request, onClose]);

  const handleVerdictChange = (value) => {
    setVerdict((prevVerdict) => ({
      ...prevVerdict,
      verdict: value,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setVerdict((prevVerdict) => ({
      ...prevVerdict,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreateVerdict(verdict);
  };
  console.log(request.research?.researchTemplate?.description);

  if (!isOpen) return null;

  const videoFile = request?.verificationFiles?.find(file => file.contentType.startsWith('video'));
  const videoUrl = videoFile ? `https://dakujemorg-002-site1.ftempurl.com/videos/${videoFile.name}` : null;
  const videoType = videoFile ? videoFile.contentType : 'video/mp4';

  const dateFormatter = new Intl.DateTimeFormat('sk-SK', {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  const formattedDate = request.createdAt ? dateFormatter.format(new Date(request.createdAt)) : 'N/A';
  
  const troopIconStyleResearch = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.research?.researchTemplate?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.9), ${attribute.rgbColorFirst}, 0.9))`;

    return {
      background: background,
    };
  }, [request.research?.researchTemplate?.gameAttribute?.id]);

  const troopIconStyleSpot = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.spot?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.9), ${attribute.rgbColorFirst}, 0.9))`;

    return {
      background: background,
    };
  }, [request.spot?.gameAttribute?.id]);


  return (
    <div className="verdict-modal-overlay" onClick={handleClickOutside}>
      <div className="verdict-modal-content" ref={modalContentRef}>
      <div className="detail-header">
              {request?.research?.researchTemplate?.iconName &&
                <div className="research-icon-container">
                    <img src={`/activity/${request?.research?.researchTemplate?.iconName}`} className="troop-icon" style={troopIconStyleResearch} />
                </div>}
                {request.spot && request?.activityTemplate?.iconName &&
                    <div className="verification-card-icon-container">
                    <img
                        src={`/activity/${request.activityTemplate?.iconName}`}
                        className="troop-icon"
                        style={troopIconStyleSpot}
                    />
                </div>}
                <div className="detail-title">
                    {request?.spot?.name && <h3>{request?.spot?.name}</h3>}
                    {request?.research?.researchTemplate?.name && <h3>{request?.research?.researchTemplate?.name}</h3>}
                </div>
                {request.createdAt && <div className="detail-dateTime">{formattedDate}</div>}
        </div>
        <div className="details-container">
         {request.spot?.spotActivities[0]?.activityTemplate.name && <div className="detail-verification-header"><strong>{request.spot?.spotActivities[0]?.activityTemplate.name || 'N/A'}</strong> </div>}
         {request.research?.researchTemplate?.activityTemplate.name && <div className="detail-verification-header"><strong>{request.research?.researchTemplate?.activityTemplate.name || 'N/A'}</strong> </div>}
         {request.spot?.spotActivities[0]?.activityLevelTemplate.levelNote && <div className="detail-item-description">{request.spot?.spotActivities[0]?.activityLevelTemplate.levelNote || 'N/A'}</div>}
         {request.research?.researchTemplate?.activityLevelTemplate?.levelNote && <div className="detail-item-description">{request.research?.researchTemplate?.activityLevelTemplate?.levelNote   || 'N/A'}</div>}
         {request.signatureString && <div className="detail-item"><KeyIconNumbers numbers={request.signatureString} /></div>}
         {request.text &&  <div className="detail-item-note">{request.text}</div>}
         {request.url && <div className="detail-item-url"> <a href={request.url} target="_blank" rel="noopener noreferrer">
    {request.url}
  </a></div>}

                 {videoUrl && (
          <div className="video-section">
            <video controls>
              {/*<source src={videoUrl} type={videoType} />*/}
              <source src={videoUrl} type={videoType} />
              Tvoj prehliadač nepodporuje tento druh zobrazenia videa...
            </video>
          </div>
        )}
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Verdikt:</label>
            <VerdictSlider value={verdict.verdict} onChange={handleVerdictChange} />
          </div>
          <div>
            <label>Text:</label>
            <textarea name="text" value={verdict.text} onChange={handleTextChange} className="styled-input"></textarea>
          </div>
          <button type="submit" className="styled-button">Potvrdiť</button>
        </form>
        <button onClick={onClose} className="styled-button close-button">Zatvoriť</button>
      </div>
    </div>
  );
};

export default CreateVerdictModal;
