import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CharacterAttributeLevel.css'; // Ensure you have the correct path to your CSS file
import CardMenuButton from '../Planet/CardMenuButton'; // Ensure correct import path
import AttributeCard from './AttributeCard'; // Importing the AttributeCard component
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { gameAttributes} from '../globalParameters.js'

const CharacterAttributeLevels = () => {
    const [characterLevel, setCharacterLevel] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCharacterLevels();
    }, []);

    const fetchCharacterLevels = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                window.location.href = '/login';
                throw new Error('No token found');
            }
            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/character/characterLevels', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            console.log("response", response);
            setCharacterLevel(response.data);
        } catch (error) {
            console.log("error", error);
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            } else {
                console.error('Error fetching:', error);
            }
        }
    };

    if (!characterLevel) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-screen">
          <div className="menu">
             <div className="menu-buttons">
                  <CardMenuButton title="Postava" onClick={() => navigate('/g/character')} />
                  <CardMenuButton title="Inventár" />
                  <CardMenuButton title="Dielňa" />
             </div>
             <div className="coin-display">
                 <FontAwesomeIcon icon={faCoins} /> <span>{characterLevel.goldBalance}</span>
             </div>
        </div>

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.strengthLevel}
                points={characterLevel.strengthLevelPoints}
                /* globalParameters*/
                name={gameAttributes.strength.name}
                nameIcon={gameAttributes.strength.nameIcon}
                goal={gameAttributes.strength.gameLevelAttributes[characterLevel.strengthLevel-1].goalPoints}
                hexColorFirst={gameAttributes.strength.hexColorFirst}
                hexColorSecond={gameAttributes.strength.hexColorSecond}
                rgbColorFirst={gameAttributes.strength.rgbColorFirst}
                rgbColorSecond={gameAttributes.strength.rgbColorSecond}
            />

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.vitalityLevel}
                points={characterLevel.vitalityLevelPoints}
                /* globalParameters*/
                name={gameAttributes.vitality.name}
                nameIcon={gameAttributes.vitality.nameIcon}
                goal={gameAttributes.vitality.gameLevelAttributes[characterLevel.vitalityLevel-1].goalPoints}
                hexColorFirst={gameAttributes.vitality.hexColorFirst}
                hexColorSecond={gameAttributes.vitality.hexColorSecond}
                rgbColorFirst={gameAttributes.vitality.rgbColorFirst}
                rgbColorSecond={gameAttributes.vitality.rgbColorSecond}
            />

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.intelligenceLevel}
                points={characterLevel.intelligenceLevelPoints}
                /* globalParameters*/
                name={gameAttributes.inteligence.name}
                nameIcon={gameAttributes.inteligence.nameIcon}
                goal={gameAttributes.inteligence.gameLevelAttributes[characterLevel.intelligenceLevel-1].goalPoints}
                hexColorFirst={gameAttributes.inteligence.hexColorFirst}
                hexColorSecond={gameAttributes.inteligence.hexColorSecond}
                rgbColorFirst={gameAttributes.inteligence.rgbColorFirst}
                rgbColorSecond={gameAttributes.inteligence.rgbColorSecond}
            />  

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.charismLevel}
                points={characterLevel.charismLevelPoints}
                /* globalParameters*/
                name={gameAttributes.charism.name}
                nameIcon={gameAttributes.charism.nameIcon}
                goal={gameAttributes.charism.gameLevelAttributes[characterLevel.charismLevel-1].goalPoints}
                hexColorFirst={gameAttributes.charism.hexColorFirst}
                hexColorSecond={gameAttributes.charism.hexColorSecond}
                rgbColorFirst={gameAttributes.charism.rgbColorFirst}
                rgbColorSecond={gameAttributes.charism.rgbColorSecond}
            />  

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.staminaLevel}
                points={characterLevel.staminaLevelPoints}
                /* globalParameters*/
                name={gameAttributes.stamina.name}
                nameIcon={gameAttributes.stamina.nameIcon}
                goal={gameAttributes.stamina.gameLevelAttributes[characterLevel.staminaLevel-1].goalPoints}
                hexColorFirst={gameAttributes.stamina.hexColorFirst}
                hexColorSecond={gameAttributes.stamina.hexColorSecond}
                rgbColorFirst={gameAttributes.stamina.rgbColorFirst}
                rgbColorSecond={gameAttributes.stamina.rgbColorSecond}
            /> 

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.combatLevel}
                points={characterLevel.combatLevelPoints}
                /* globalParameters*/
                name={gameAttributes.combat.name}
                nameIcon ={gameAttributes.combat.nameIcon}
                goal={gameAttributes.combat.gameLevelAttributes[characterLevel.combatLevel-1].goalPoints}
                hexColorFirst={gameAttributes.combat.hexColorFirst}
                hexColorSecond={gameAttributes.combat.hexColorSecond}
                rgbColorFirst={gameAttributes.combat.rgbColorFirst}
                rgbColorSecond={gameAttributes.combat.rgbColorSecond}
            />   

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.resilenceLevel}
                points={characterLevel.resilenceLevelPoints}
                /* globalParameters*/
                name={gameAttributes.resilence.name}
                nameIcon ={gameAttributes.resilence.nameIcon}
                goal={gameAttributes.resilence.gameLevelAttributes[characterLevel.resilenceLevel-1].goalPoints}
                hexColorFirst={gameAttributes.resilence.hexColorFirst}
                hexColorSecond={gameAttributes.resilence.hexColorSecond}
                rgbColorFirst={gameAttributes.resilence.rgbColorFirst}
                rgbColorSecond={gameAttributes.resilence.rgbColorSecond}
            /> 

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.strategyLevel}
                points={characterLevel.strategyLevelPoints}
                /* globalParameters*/
                name={gameAttributes.strategy.name}
                nameIcon ={gameAttributes.strategy.nameIcon}
                goal={gameAttributes.strategy.gameLevelAttributes[characterLevel.strategyLevel-1].goalPoints}
                hexColorFirst={gameAttributes.strategy.hexColorFirst}
                hexColorSecond={gameAttributes.strategy.hexColorSecond}
                rgbColorFirst={gameAttributes.strategy.rgbColorFirst}
                rgbColorSecond={gameAttributes.strategy.rgbColorSecond}
            /> 

            <AttributeCard
                /* DATABAZA */
                level={characterLevel.unityLevel}
                points={characterLevel.unityLevelPoints}
                /* globalParameters*/
                name={gameAttributes.unity.name}
                nameIcon ={gameAttributes.unity.nameIcon}
                goal={gameAttributes.unity.gameLevelAttributes[characterLevel.unityLevel-1].goalPoints}
                hexColorFirst={gameAttributes.unity.hexColorFirst}
                hexColorSecond={gameAttributes.unity.hexColorSecond}
                rgbColorFirst={gameAttributes.unity.rgbColorFirst}
                rgbColorSecond={gameAttributes.unity.rgbColorSecond}
            /> 
        
          {/*  <h2>Character Stats</h2>
            <div className="table-container">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Stat</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Life Steal</td>
                            <td>{characterLevel.lifeSteal}</td>
                        </tr>
                        <tr>
                            <td>Life Steal Magic</td>
                            <td>{characterLevel.lifeStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Mana Steal</td>
                            <td>{characterLevel.manaSteal}</td>
                        </tr>
                        <tr>
                            <td>Mana Steal Magic</td>
                            <td>{characterLevel.manaStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Energy Steal</td>
                            <td>{characterLevel.energySteal}</td>
                        </tr>
                        <tr>
                            <td>Energy Steal Magic</td>
                            <td>{characterLevel.energyStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Cool Down Reducer</td>
                            <td>{characterLevel.coolDownReducer}</td>
                        </tr>
                        <tr>
                            <td>Attack Damage</td>
                            <td>{characterLevel.attackDamage}</td>
                        </tr>
                        <tr>
                            <td>Accuracy</td>
                            <td>{characterLevel.accuracy}</td>
                        </tr>
                        <tr>
                            <td>Attack Speed</td>
                            <td>{characterLevel.attackSpeed}</td>
                        </tr>
                        <tr>
                            <td>Critical Hit Damage</td>
                            <td>{characterLevel.criticalHitDamage}</td>
                        </tr>
                        <tr>
                            <td>Critical Hit Posibility</td>
                            <td>{characterLevel.criticalHitPosibility}</td>
                        </tr>
                        <tr>
                            <td>Magic Attack</td>
                            <td>{characterLevel.magicAttack}</td>
                        </tr>
                        <tr>
                            <td>Armor</td>
                            <td>{characterLevel.armor}</td>
                        </tr>
                        <tr>
                            <td>Magic Resistance</td>
                            <td>{characterLevel.magicResistance}</td>
                        </tr>
                        <tr>
                            <td>Team Work</td>
                            <td>{characterLevel.teamWork}</td>
                        </tr>
                        <tr>
                            <td>Capacity</td>
                            <td>{characterLevel.capacity}</td>
                        </tr>
                        <tr>
                            <td>Dodge</td>
                            <td>{characterLevel.dodge}</td>
                        </tr>
                        <tr>
                            <td>Exp</td>
                            <td>{characterLevel.exp}</td>
                        </tr>
                        <tr>
                            <td>Mine Gold</td>
                            <td>{characterLevel.mineGold}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </div>
    );
};

export default CharacterAttributeLevels;
