import React from 'react';

import './BottomNavBar.css';
import { PiPersonArmsSpreadFill } from "react-icons/pi";
import { BsPinMapFill } from "react-icons/bs";
import { GiSwordsEmblem, GiSpellBook } from "react-icons/gi";
import { FaShop } from "react-icons/fa6";

const BottomNavbarOffMenu = () => {


  
  return (
    <div className="bottom-navbar">
      <button className={ ''} >
        <PiPersonArmsSpreadFill className="fa-iconB" />
      </button>
      <button className={ ''} >
        <BsPinMapFill className="fa-iconB" />
      </button>
      <button className={''} >
        <GiSwordsEmblem className="fa-iconB" />
      </button>
      <button className={''} >
        <GiSpellBook className="fa-iconB" />
      </button>
      <button className={''} >
        <FaShop className="fa-iconB" />
      </button>
    </div>
  );
};

export default BottomNavbarOffMenu;
