import React from 'react';
import './AttributeCard.css'; // Ensure you have the correct path to your CSS file

const AttributeCard = ({ name, nameIcon, level, points, goal, hexColorFirst, hexColorSecond, rgbColorFirst, rgbColorSecond }) => {
   
  console.log("name", name);
    const progress = (points / goal) * 100;
   console.log("color: " +  `linear-gradient(to right, ${hexColorSecond}, ${hexColorFirst})`)

   const cardStyle = {
    border: `1px solid ${hexColorSecond}`,
    background: `linear-gradient(to right bottom, ${rgbColorSecond}, 0.4), ${rgbColorFirst}, 0.4))`, // Border color matching the first color of the gradient
};
    const levelStyle = {
        background: `linear-gradient(to right bottom, ${hexColorSecond}, ${hexColorFirst})`, // Use the color prop for the background color
        color: "black" // Ensuring text color is white for better contrast
    };

    const progressBarStyle = {
        width: `${progress}%`,
        background:  `linear-gradient(to right, ${hexColorSecond}, ${hexColorFirst})` // Use the dynamic color for the progress bar
    };

    const getIconPath = (name) => {
        const formattedName = name.toLowerCase().replace(' ', ''); // Remove spaces and convert to lowercase
        return `/activity/${formattedName}`; // Assuming the icon name directly matches the attribute name
    };

    return (
        <div className="attribute-card" style ={cardStyle}>
           <div className="attribute-level" style={levelStyle}>
    <img src={getIconPath(nameIcon)} alt={`${name} icon`} className="attribute-icon" />
   
</div>
            <div className="attribute-details">
                <div className="attribute-name"> {level} {name}</div>
                <div className="attribute-progress">
                    <div className="attribute-progress-bar" style={progressBarStyle }></div>
                    <div className="attribute-progress-text">{points} / {goal}</div>
                </div>
            </div>
        </div>
    );
};

export default AttributeCard;
