import React, { useState, useMemo } from 'react';
import './EventCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

import { gameAttributes, idToKeyMap} from '../globalParameters.js'

const EventCard = ({ event, onOpenModalRegister, onOpenModalUnregister }) => {


  


  const handleClickRegister = () => {
    onOpenModalRegister(event);

};  

const handleClickUnregister = () => {
  onOpenModalUnregister(event);

};  

const [expanded, setExpanded] = useState(false);

const toggleDescription = () => {
  setExpanded(!expanded);
};
 

/*EventCharacter show*/
const [showCharacters, setShowCharacters] = useState(false);
const toggleCharacters = () => {
  console.log("toggleCharacters");
  console.log("showCharacters", showCharacters);
  setShowCharacters(prev => !prev);
};




  const cardStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[event.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.6), ${attribute.rgbColorFirst}, 0.6))`
    var borderColor = attribute.hexColorSecond;
    if (event.status == 10) {
      borderColor = "#f8f8f8"
    }
    return {
      background: background,
      border: `2px solid ${borderColor}`,
      backgroundColor: `${attribute.hexColorBackground}`,
      color: '#fff' // Biela textová farba pre lepšiu čitateľnosť
    };
  }, [event.gameAttribute.id]);

  const troopIconStyle = useMemo(() => {
 
    const attributeKey = idToKeyMap[event.gameAttribute.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, rgb(255, 255, 255, 0.3), rgb(255, 255, 255, 0.3))`
 
    return {
      background: background,
      width: '30px', // sets the width to 100% of its container
      height: 'auto', // maintains the aspect ratio of the image
      objectFit: 'contain'
    };
  }, [event.gameAttribute.id]);

  const dateFormatter = new Intl.DateTimeFormat('sk-SK', {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  
  const formattedDate = event.startDateTime ? dateFormatter.format(new Date(event.startDateTime)) : 'N/A';


   const renderRewards = (activity) => {
    console.log(activity);
          return (
              <div className="reward-container">
                  {Object.keys(gameAttributes).map((key) => {
                      console.log(gameAttributes);
                      const rewardKey = `reward${key.charAt(0).toUpperCase() + key.slice(1)}Points`; // Construct the reward key dynamically
                      const rewardValue = event[rewardKey];
                      console.log(rewardKey);
                      console.log(rewardValue);
                      if (rewardValue > 0) {
                          const attribute = gameAttributes[key];
                          console.log("farba", `linear-gradient(to right bottom, ${attribute.hexColorSecond}, ${attribute.hexColorFirst})`);
                          const background = `linear-gradient(to right bottom, ${attribute.hexColorSecond}, ${attribute.hexColorFirst})`;
                          const iconSrc = `/activity/${attribute.nameIcon}`;
                          return (
                              <div key={key} className="event-attribute-level" style={{ background }}>
                                  <div className="event-marker-icon">
                                      <img src={iconSrc} alt={key} className="event-attribute-icon" style={{position: 'relative', top: '20%'}} />
                                  </div>
                                  <img src="/activity/label.png" alt="label" className="label-icon" style={{ width: '27px', position: 'absolute', top: '-10%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                  <div className="level-star" style={{ position: 'absolute', top: '-15%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, fontSize: '11px' }}>{rewardValue}</div>
                              </div>
                          );
                      }
                      return null;
                  })}
              </div>
          );
      };

  return (
    <>
    <div className={`event-card`} id={'event-'+event.id} style={cardStyle} >
      <div className="event-card-header">
        <div className="event-icon-container">
                <img
                    src={`/activity/${event.iconPath}`}
                    className="troop-icon"
                    style={troopIconStyle}
                />
            </div>
        <div className="event-name">
          {event.name}
        </div>
        <div className="event-card-timeDuration">
           <div className="event-date">{formattedDate}</div>
           <div className="event-duration">
           {event.duration + "'"}
              {/* <img src="/activity/timer.png" alt="Timer Icon" className="timer-icon" />*/}
           </div>
        </div>
      </div>
      <div className={`event-description ${expanded ? 'expanded' : ''}`} onClick={toggleDescription}>
      {event.description}
        <br></br>
        <br></br>
        {event.address}
      </div>
      <div className="event-footer">
          <div className="event-people" onClick={toggleCharacters}>
            <img src="/activity/person.png" alt="Person Icon" className="person-icon" />
             {event.eventCharacters ? event.eventCharacters.length : 0}
          </div>
          {renderRewards(event)}
           <div className="event-cost">
                  <FontAwesomeIcon icon={faCoins} /> <span>{event.cost}</span>
           </div>
      </div>
      {showCharacters && event.eventCharacters && (
                <div className="event-characters-list">
                  {event.eventCharacters?.map((eventCharacter) => (
                   <div key={eventCharacter.id}>{eventCharacter?.character?.firstname} {eventCharacter?.character?.surname}</div>
                   ))}

                  {event.status != 10 &&<button onClick={handleClickRegister} className="styled-button">Prihlásiť</button>}
                  {event.status == 10 &&<button onClick={handleClickUnregister} className="styled-button">Odhlásiť</button>}
                 </div>
               )}
      
    </div>
     
    </>
  );
};

export default EventCard;
