import React from 'react';
import './KeyIconNumbers.css'; // Uistite sa, že máte štýly v tomto CSS súbore

const IconNumbers = ({ numbers }) => {
  // Rozdelíme vstupný reťazec do poľa čísel
  const numberArray = numbers.split('-');

  return (
    <div className="key-icon-number-container">
      {numberArray.map((number, index) => (
        <div key={index} className="key-icon">
          {number}
        </div>
      ))}
    </div>
  );
};

export default IconNumbers;