// components/ActivityTemplateTable.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ActivityTemplateTable = ({ activities, setActivities }) => {
  const [editingActivity, setEditingActivity] = useState(null);





  const handleEditClick = (activity) => {
    setEditingActivity(activity);
  };


  const navigate = useNavigate();

  const handleDetailClick = (activityTemplate) => {
    navigate(`/admin/activityTemplate/${activityTemplate.id}`); 
  };


  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/updateActivityTemplate/${editingActivity.id}`, editingActivity);
      setActivities((prev) => prev.map((act) => (act.id === editingActivity.id ? response.data : act)));
      setEditingActivity(null); // Close the edit form
    } catch (error) {
      console.error('Error updating activity template:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingActivity((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Game Attribute ID</th>
            <th>Icon Name</th>
            <th>Actions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity.id}>
              <td>{activity.name}</td>
              <td>{activity.description}</td>
              <td>{activity.gameAttributeId}</td>
              <td>{activity.iconName}</td>
              <td>
                <button onClick={() => handleEditClick(activity)}>Update</button>
              </td>
              <td>
                <button onClick={() => handleDetailClick(activity)}>Detail</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingActivity && (
        <form onSubmit={handleUpdateSubmit}>
          <h3>Update Activity Template</h3>
          <label>
            Name:
            <input type="text" name="name" value={editingActivity.name} onChange={handleChange} />
          </label>
          <label>
            Description:
            <input type="text" name="description" value={editingActivity.description} onChange={handleChange} />
          </label>
          <label>
            Game Attribute ID:
            <input type="number" name="gameAttributeId" value={editingActivity.gameAttributeId} onChange={handleChange} />
          </label>
          <label>
            Icon Name:
            <input type="text" name="iconName" value={editingActivity.iconName} onChange={handleChange} />
          </label>
          <button type="submit">Save Changes</button>
          <button onClick={() => setEditingActivity(null)}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default ActivityTemplateTable;
