import React, { useState, useEffect } from 'react';
import DraggableCard from './DraggableCard';
import { useParams } from 'react-router-dom';

const ArchetypeScreen = () => {
    const { registerCode } = useParams();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    const [isValidRegisterCode, setIsValidRegisterCode] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        console.log('Register Code:', registerCode);
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/validateRegisterCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ registerCode })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Validation Response:', data);
                if (data.isValid) {
                    setIsValidRegisterCode(true);
                    fetchSurveyQuestions();
                 /*   fetchSurveyPointQuestions();*/
                } else {
                    setIsValidRegisterCode(false);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error validating register code:', error);
                setLoading(false);
            });
    }, [registerCode]);

    const fetchSurveyQuestions = () => {
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/survey')
            .then(response => response.json())
            .then(data => {
                console.log('Survey Questions:', data);
                setQuestions(data);
                setSelectedAnswers(new Array(data.length).fill([]));
            })
            .catch(error => console.error('Error fetching questions:', error));
    };

    
    const handleSubmit = () => {

        console.log(selectedAnswers);

        const submissionData = {
            registerCode,
            answers: selectedAnswers,
        };


        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/answers', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(submissionData)
        })
        .then(response => response.json())
        .then(data => console.log('Success:', data))
        .catch((error) => console.error('Error:', error));
    };

    const handleSelectAnswer = answer => {
        const updatedSelected = [...selectedAnswers];
        updatedSelected[currentQuestionIndex] = [...updatedSelected[currentQuestionIndex], answer];
        setSelectedAnswers(updatedSelected);

        // Remove the selected answer from the current question's answer list
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].answers = updatedQuestions[currentQuestionIndex].answers.filter(a => a.id !== answer.id);
        setQuestions(updatedQuestions);
    };

    const handleDeselectAnswer = answer => {
        const updatedSelected = [...selectedAnswers];
        updatedSelected[currentQuestionIndex] = updatedSelected[currentQuestionIndex].filter(a => a.id !== answer.id);
        setSelectedAnswers(updatedSelected);

        // Add the deselected answer back to the current question's answer list
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].answers = [...updatedQuestions[currentQuestionIndex].answers, answer];
        setQuestions(updatedQuestions);
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestion = questions[currentQuestionIndex] || { answers: [] };
    const currentSelectedAnswers = selectedAnswers[currentQuestionIndex] || [];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isValidRegisterCode) {
        return <div>Invalid or used register code.</div>;
    }

    return (
        <div className="question-container">
            <h2>{currentQuestion.text}</h2>
            <div className="answer-container">
                <div className="selected-container">
                    {currentSelectedAnswers.map(answer => (
                        <DraggableCard
                            key={answer.id}
                            description={answer.text}   // Assuming no description provided
                            onClick={() => handleDeselectAnswer(answer)}
                        />
                    ))}
                </div>
                <div className="available-container">
                    {currentQuestion.answers.map(answer => (
                        <DraggableCard
                            key={answer.id}
                            description={answer.text}  // Assuming no description provided
                            onClick={() => handleSelectAnswer(answer)}
                        />
                    ))}
                </div>
            </div>
            <div className="navigation-buttons">
    {currentQuestionIndex > 0 && (
        <span className="arrow left-arrow" onClick={handlePreviousQuestion}>
            &#8592;
        </span>
    )}
    <div className="progress-container">
        <div className="progress-bar" style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}></div>
        <span className="progress-indicator">
            {currentQuestionIndex + 1}/{questions.length}
        </span>
    </div>
    {currentQuestionIndex < questions.length - 1 ? (
        <span className="arrow right-arrow" onClick={handleNextQuestion}>
            &#8594;
        </span>
    ) : (
        <span className="arrow check-icon" onClick={handleSubmit}>
            &#10003;
        </span>
    )}
</div>

        </div>
    );
};

export default ArchetypeScreen;
