// components/ActivityTemplateCreateForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ActivityTemplateCreateForm = ({ onActivityCreated }) => {
  const [activity, setActivity] = useState({
    gameAttributeId: 0,
    name: '',
    description: '',
    iconName: '',
  });

  const [gameAttributes, setGameAttributes] = useState([]);

  useEffect(() => {
    const fetchGameAttributes = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/GameAttributeDomain`);
        setGameAttributes(response.data);
      } catch (error) {
        console.error('Error fetching game attributes:', error);
      }
    };

    fetchGameAttributes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setActivity({
      ...activity,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createActivityTemplate`, activity);
      alert('Activity Template created successfully!');
      onActivityCreated(response.data); // Notify parent component
      setActivity({
        gameAttributeId: 0,
        name: '',
        description: '',
        iconName: '',
      });
    } catch (error) {
      alert('Failed to create activity template');
      console.error('Error creating activity template:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="name" value={activity.name} onChange={handleChange} />
      </label>
      <label>
        Description:
        <input type="text" name="description" value={activity.description} onChange={handleChange} />
      </label>
      <label>
        Game Attribute:
        <select name="gameAttributeId" value={activity.gameAttributeId} onChange={handleChange}>
          <option value="">Select Game Attribute</option>
          {gameAttributes.map((attr) => (
            <option key={attr.id} value={attr.id}>
              {attr.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Icon Name:
        <input type="text" name="iconName" value={activity.iconName} onChange={handleChange} />
      </label>
      <button type="submit">Create Activity Template</button>
    </form>
  );
};

export default ActivityTemplateCreateForm;
