import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';

import './VerificationDetailModal.css';
import { gameAttributes, idToKeyMap} from '../globalParameters';
import KeyIconNumbers from './KeyIconNumbers';


const VerificationDetailModal = ({ isOpen, onClose, request, style, styleOverlay  }) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoType, setVideoType] = useState(null);
  const overlayRef = useRef(null);  // Reference for the modal overlay
  const contentRef = useRef(null);  // Reference for the modal content

  useEffect(() => {
    if (request) {
      console.log("video request", request);
      const videoFile = request.verificationFiles?.find(file => file.contentType.startsWith('video'));
      if (videoFile) {
        console.log("videofile",videoFile);
       /* setVideoUrl(`/videos/${videoFile.name}`);*/
       setVideoUrl(`https://dakujemorg-002-site1.ftempurl.com/videos/${videoFile.name}`);
        setVideoType(videoFile.contentType);
      }
    }
  }, [request]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !contentRef.current.contains(event.target)) {
        onClose();  // Only close if click is outside content
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const cancelVerification = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found. Please log in.');
        // Redirect to login or refresh the token
        return;
      }
  
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/cancelVerification/${request.id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        withCredentials: true,
      });
  
      console.log('Verification cancelled successfully:', response.data);
      window.location.reload();
        // or update the state to show in the UI
      onClose(); // Close the modal on successful cancellation
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Failed to cancel verification:', error);
        alert('Failed to cancel verification. Please try again.'); 
      }
    }
  };
  
  const troopIconStyleResearch = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.research?.researchTemplate?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.9), ${attribute.rgbColorFirst}, 0.9))`;

    return {
      background: background,
    };
  }, [request.research?.researchTemplate?.gameAttribute?.id]);

  const troopIconStyleSpot = useMemo(() => {
 
    const attributeKey = idToKeyMap[request.spot?.gameAttribute?.id]; // Získanie správneho kľúča pre ID
    const attribute = gameAttributes[attributeKey];
    if (!attribute) return {};

    const  background = `linear-gradient(to right bottom, ${attribute.rgbColorSecond}, 0.9), ${attribute.rgbColorFirst}, 0.9))`;

    return {
      background: background,
    };
  }, [request.spot?.gameAttribute?.id]);


 

  const dateFormatter = new Intl.DateTimeFormat('sk-SK', {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  
  const formattedDate = request.createdAt ? dateFormatter.format(new Date(request.createdAt)) : 'N/A';

  if (!isOpen) return null;
console.log("request", request);
  return (
    <div className="verification-modal-overlay" ref={overlayRef} style={styleOverlay}>
      <div className="verification-modal-content" ref={contentRef} style={style}>
      <div className="detail-header">
              {request?.research?.researchTemplate?.iconName &&
                <div className="research-icon-container">
                    <img src={`/activity/${request?.research?.researchTemplate?.iconName}`} className="troop-icon" style={troopIconStyleResearch} />
                </div>}
                {request.spot && request?.activityTemplate?.iconName &&
                    <div className="verification-card-icon-container">
                    <img
                        src={`/activity/${request.activityTemplate?.iconName}`}
                        className="troop-icon"
                        style={troopIconStyleSpot}
                    />
                </div>}
                <div>
                    {request?.spot?.name && <h3>{request?.spot?.name}</h3>}
                    {request?.research?.researchTemplate?.name && <h3>{request?.research?.researchTemplate?.name}</h3>}
                </div>
                {request.createdAt && <div className="detail-item">{formattedDate}</div>}
        </div>
        <div className="details-container">
         {request.spot?.spotActivities[0]?.activityTemplate.name && <div className="detail-verification-header"><strong>{request.spot?.spotActivities[0]?.activityTemplate.name || 'N/A'}</strong> </div>}
         {request.research?.researchTemplate?.activityTemplate.name && <div className="detail-verification-header"><strong>{request.research?.researchTemplate?.activityTemplate.name || 'N/A'}</strong> </div>}
         {request.spot?.spotActivities[0]?.activityLevelTemplate.levelNote && <div className="detail-item-description">{request.spot?.spotActivities[0]?.activityLevelTemplate.levelNote || 'N/A'}</div>}
         {request.research?.researchTemplate?.activityLevelTemplate?.levelNote && <div className="detail-item-description">{request.research?.researchTemplate?.activityLevelTemplate?.levelNote   || 'N/A'}</div>}
         {request.signatureString && <div className="detail-item"><KeyIconNumbers numbers={request.signatureString} /></div>}
         {request.text &&  <div className="detail-item-note">{request.text}</div>}
         {request.url && <div className="detail-item-url"> <a href={request.url} target="_blank" rel="noopener noreferrer">
    {request.url}
  </a></div>}

        </div>
        {videoUrl && (
          <div className="video-section">
            <video controls>
              {/*<source src={videoUrl} type={videoType} />*/}
              <source src={videoUrl} type={videoType} />
              Tvoj prehliadač nepodporuje tento druh zobrazenia videa...
            </video>
          </div>
        )}
        <button onClick={onClose} className="styled-button">Zatvoriť</button>
        <button onClick={cancelVerification} className="styled-button">Zrušiť verifikáciu</button>
      </div>
    </div>
  );
};

export default VerificationDetailModal;
