// components/SpotTable.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SpotTable = ({ spots }) => {
  const navigate = useNavigate();

  const handleDetailClick = (spotId) => {
    navigate(`/admin/spot/${spotId}`); // Navigate to the spot details page
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Level</th>
          <th>Status</th>
          <th>Actions</th> {/* Added Actions header */}
        </tr>
      </thead>
      <tbody>
        {spots.map((spot) => (
          <tr key={spot.id}>
            <td>{spot.name}</td>
            <td>{spot.description}</td>
            <td>{spot.level}</td>
            <td>{spot.status}</td>
            <td>
              <button onClick={() => handleDetailClick(spot.id)}>Details</button>
            </td> {/* Added button to navigate to details */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpotTable;
