import React from 'react';
import './VerdictSlider.css';

const verdicts = [
  { value: 0, label: 'Blokovať', emoji: '😡' },
  { value: 1, label: 'Neúspešne', emoji: '😞' },
  { value: 2, label: 'Uspokojivé', emoji: '😊' },
  { value: 3, label: 'Perfektné', emoji: '😁' }
];

const VerdictSlider = ({ value, onChange }) => {
  const selectedVerdict = verdicts.find(verdict => verdict.value === value);

  return (
    <div className="verdict-slider">
      <div className="slider-container">
        <input
          type="range"
          min="0"
          max="3"
          step="1"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          className="slider"
          style={{ backgroundSize: `100% 100%`, backgroundPosition: `${(value / 3) * 100}% 50%` }}
        />
        <div className="emoji-slider-thumb" style={{ left: `${(value / 3) * 100}%` }}>
          {selectedVerdict.emoji}
        </div>
      </div>
      <div className="slider-label">
        <span>{selectedVerdict.label}</span>
      </div>
    </div>
  );
};

export default VerdictSlider;
