// components/SpotTable.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TroopTable = ({ troops }) => {
  const navigate = useNavigate();
console.log(troops);
  const handleDetailClick = (troopTemplateId) => {
    navigate(`/admin/troopTemplate/${troopTemplateId}`); // Navigate to the spot details page
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>GameAttribute</th>
          <th>Troop Type</th>
          <th>Troop Attack Type</th>
          <th>Consumption</th> 
          <th>DETAIL</th>
        </tr>
      </thead>
      <tbody>
        {troops.map((troop) => (
          <tr key={troop.id}>
            <td>{troop?.name}</td>
            <td>{troop?.gameAttribute?.name}</td>
            <td>{troop?.troopType?.name}</td>
            <td>{troop?.troopAttackType?.typeName}</td>
            <td>{troop?.consumption}</td> 
            <td>
              <button onClick={() => handleDetailClick(troop.id)}>Details</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TroopTable;
