  import React from 'react';
  import './CardMenuButton.css';

  const CardMenuButton = ({ title, onClick }) => {
    return (
      <div className="card-menu-button" onClick={onClick}>
        {title}
      </div>
    );
  };

  export default CardMenuButton;