import React from 'react';

const ProgressBar = ({ progress }) => {
  const containerStyles = {
    width: '100%',
    backgroundColor: '#ddd',
    borderRadius: '8px',
    position: 'relative', // Allows absolute positioning within
    height: '20px', // Fixed height for the container
    display: 'flex',
    alignItems: 'center', // Centers the label vertically
    justifyContent: 'center' // Centers the label horizontally
  };

  const fillerStyles = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: progress >= 100 ? '#4CAF50' : '#ffcc00',
    borderRadius: 'inherit',
    transition: 'width 0.5s ease-in-out',
    display: 'flex',
    alignItems: 'center'
  };

  const labelStyles = {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute', // Positions absolutely within the relative container
    width: '100%', // Full width of the container
    textAlign: 'center', // Centers text horizontally
    userSelect: 'none' // Prevents text selection
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
      <span style={labelStyles}>
        {progress.toFixed(0)}%
      </span>
    </div>
  );
};

export default ProgressBar;