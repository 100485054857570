import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './Login'; // Your login component

import Register from './Register';

import Layout from './Layout';
import LayoutMenuOff from './LayoutMenuOff';





import SpellType from './Spell/SpellType';
import SpellTemplate from './Spell/SpellTemplate';
import SpellLevelTemplate from './Spell/SpellLevelTemplate';
import SpellLevelTemplateDetails from './Spell/SpellLevelTemplateDetail';

import ItemTemplates from './Item/ItemTemplate';


import CharacterAttributeLevel from './Character/CharacterAttributeLevel';
import CharacterItems from './Character/CharacterItems';
import CharacterItemCraft from './Character/CharacterItemCraft';

import VideoWithBeep from './Spell/VideoWithBeep';
import MapComponent from './Activity/MapSpot';
import CharacterSpells from './Spell/CharacterSpell';


import VerificationRequestsScreen from './Market/VerificationRequestsScreen';
import ResearchToLearn from './Spell/ResearchToLearn';
import ResearchLearned from './Spell/ResearchLearned';


import CombatKeys from './Combat/CombatKeys';
import Market from './Market/Market';
import ResearchLearning from './Spell/ResearchLearning';

import ArchetypeScreen from './Test/ArchetypeScreen';

import ArchetypeScreenPoint from './Test/ArchetypeScreenPoint';

import SpotAdmin from './Admin/SpotAdmin';
import SpotDetail from './Admin/SpotDetail';

import TroopAdmin from './Admin/TroopAdmin';
import TroopDetail from './Admin/TroopDetail';

import ChallengeTemplateAdmin from './Admin/ChallengeTemplateAdmin';
import ItemTemplateAdmin from './Admin/ItemTemplateAdmin';
import ItemTemplateDetail from './Admin/ItemTemplateDetail';
import ActivityTemplateAdmin from './Admin/ActivityTemplateAdmin';
import ActivityTemplateDetail from './Admin/ActivityTemplateDetail';

import CombatSelectionScreen  from './Combat/CombatSelectionScreen';

import Events from './Activity/Events';
import OnlineEvents from './Activity/OnlineEvents';


function App() {
  const [scrollTop, setScrollTop] = useState(0);
  return (
    <Router>
      <Routes>
        <Route path="/g" element={<Layout setScrollTop={setScrollTop} />}>

        



          {/*Character */}
          <Route path="character" element={<CharacterAttributeLevel />} />
          <Route path="characterItems" element={<CharacterItems />} />
          <Route path="characterItemCraft" element = {<CharacterItemCraft />} />


          <Route path="itemTemplates" element={<ItemTemplates />} />

 


        

          {/*Map */}
          <Route path="mapSpots" element={<MapComponent />} />
          <Route path="events" element={<Events />} />
          <Route path="onlineEvents" element={<OnlineEvents />} />
         

           {/*Combat */}
          <Route path="combatKeys" element = {<CombatKeys /> } />


          <Route path="combat/:spotId" element={<CombatSelectionScreen scrollTop={scrollTop}/>} />

          {/* Spell */}
          <Route path="CharacterSpell" element={<CharacterSpells />} />
          <Route path="researchToLearn" element = {<ResearchToLearn />} />
          <Route path="researchLearning" element = {<ResearchLearning />} />
          <Route path="researchLearned" element = {<ResearchLearned />} />


          <Route path="video/:videoId" element={<VideoWithBeep />} />

          <Route path="SpellType" element={<SpellType />} />
          <Route path="SpellTemplate" element={<SpellTemplate />} />
          <Route path="SpellTemplate/:id" element={<SpellLevelTemplate />} />
          <Route path="spellTemplate/:spellTemplateId/SpellLevelTemplate/:spellLevelTemplateId" element={<SpellLevelTemplateDetails />} />


    
          {/*Market */}
          <Route path="market" element = {<Market />} />
          <Route path="verifications" element={<VerificationRequestsScreen/>} />

          <Route path="survey/:registerCode" element={<ArchetypeScreen/>} />
          <Route path="survey2/:registerCode" element={<ArchetypeScreenPoint/>} />
        </Route>
        <Route path="video/:videoId" element={<VideoWithBeep />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="mapSpotsTest" element={<MapComponent />} />

        <Route path="/x" element={<LayoutMenuOff />}>
           <Route path="survey/:registerCode" element={<ArchetypeScreen/>} />
           <Route path="survey2/:registerCode" element={<ArchetypeScreenPoint/>} />
        </Route>

        <Route path="/admin" element={<LayoutMenuOff />}>
           <Route path="spot"  element={<SpotAdmin/>} />
           <Route path="spot/:spotId"  element={<SpotDetail/>} />
           <Route path="troop"  element={<TroopAdmin/>} />
           <Route path="troop/:troopTemplateId"  element={<TroopDetail/>} />
           <Route path="troopTemplate/:troopTemplateId"  element={<TroopDetail/>} />

           <Route path="challengeTemplate"  element={<ChallengeTemplateAdmin/>} />

           <Route path="itemTemplate"  element={<ItemTemplateAdmin/>} />
           <Route path="itemTemplate/:itemTemplateId"  element={<ItemTemplateDetail/>} />

           <Route path="activityTemplate"  element={<ActivityTemplateAdmin/>} />
           <Route path="activityTemplate/:activityTemplateId"  element={<ActivityTemplateDetail/>} />
        </Route>
   
   
      </Routes>
    </Router>
  );
}

export default App;
