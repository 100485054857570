// components/ItemTemplateAdmin.js
import React, { useEffect, useState } from 'react';
import ItemTemplateTable from './ItemTemplateTable';
import ItemTemplateCreateForm from './ItemTemplateCreateForm';
import axios from 'axios';

const ItemTemplateAdmin = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/admin/allitemtemplates', { headers });
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching item templates:', error);
      }
    };

    fetchItems();
  }, []);

  const handleItemCreated = (newItem) => {
    setItems([...items, newItem]);
  };

  return (
    <div>
      <h1>Item Templates Dashboard</h1>
      <ItemTemplateTable items={items} setItems={setItems} />
      <ItemTemplateCreateForm onItemCreated={handleItemCreated} />
    </div>
  );
};

export default ItemTemplateAdmin;
