import React from 'react';
import './SpotActivitiesTable.css';

// SpotActivitiesTable Component
const SpotEnemyTroopsTable = ({ troops, onDelete }) => {
  return (
    <table className="spot-activities-table">
      <thead>
        <tr>
          <th>Troop Name</th>
          <th>Rarity</th>
          <th>Count</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {troops.map(troop => (
          <tr key={troop.id}>
            <td>{troop.troopTemplate.name}</td>
            <td>{troop.rarity.name}</td>
            <td>{troop.count}</td>
            <td>
            <button onClick={() => onDelete(troop.id)}>
  {troop.status === 1 ? "Deactivate" : "Activate"}
</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpotEnemyTroopsTable;
