import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DraggableCard from './DraggableCard';
import PointCard from './PointCard';

const ArchetypeScreenPoint = () => {
    const { registerCode } = useParams();
    const [questions, setQuestions] = useState([]);
    const [pointQuestions, setPointQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [selectedPointAnswers, setSelectedPointAnswers] = useState([]);
    const [validationError, setValidationError] = useState("");
    const [isValidRegisterCode, setIsValidRegisterCode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [displayingPointQuestions, setDisplayingPointQuestions] = useState(false);

    useEffect(() => {
        console.log('Register Code:', registerCode);
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/validateRegisterCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ registerCode })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Validation Response:', data);
                if (data.isValid) {
                    setIsValidRegisterCode(true);
                    fetchSurveyQuestions();
                    fetchSurveyPointQuestions();
                } else {
                    setIsValidRegisterCode(false);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error validating register code:', error);
                setLoading(false);
            });
    }, [registerCode]);

    const fetchSurveyQuestions = () => {
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/survey')
            .then(response => response.json())
            .then(data => {
                console.log('Survey Questions:', data);
                setQuestions(data);
                setSelectedAnswers(new Array(data.length).fill([]));
            })
            .catch(error => console.error('Error fetching questions:', error));
    };

    const fetchSurveyPointQuestions = () => {
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/surveyPoint')
            .then(response => response.json())
            .then(data => {
                console.log('Survey Point Questions:', data);
                setPointQuestions(data);
                setSelectedPointAnswers(data.map(question => 
                    question.answers.map(answer => ({ id: answer.id, points: 0 }))
                ));
            })
            .catch(error => console.error('Error fetching point questions:', error));
    };

    const handleSubmit = () => {
        const submissionData = {
            registerCode,
            answers: selectedAnswers,
            pointAnswers: selectedPointAnswers
        };

        console.log('Submission Data:', submissionData);
        fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/answers', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(submissionData)
        })
        .then(response => response.json())
        .then(data => console.log('Success:', data))
        .catch((error) => console.error('Error:', error));
    };

    const handleSelectAnswer = answer => {
        const updatedSelected = [...selectedAnswers];
        updatedSelected[currentQuestionIndex] = [...updatedSelected[currentQuestionIndex], answer];
        setSelectedAnswers(updatedSelected);

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].answers = updatedQuestions[currentQuestionIndex].answers.filter(a => a.id !== answer.id);
        setQuestions(updatedQuestions);
        setValidationError("");  // Clear validation error

        console.log('Selected Answers:', updatedSelected);
    };

    const handleDeselectAnswer = answer => {
        const updatedSelected = [...selectedAnswers];
        updatedSelected[currentQuestionIndex] = updatedSelected[currentQuestionIndex].filter(a => a.id !== answer.id);
        setSelectedAnswers(updatedSelected);

        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].answers = [...updatedQuestions[currentQuestionIndex].answers, answer];
        setQuestions(updatedQuestions);

        console.log('Deselected Answer:', updatedSelected);
    };

    const handlePointChange = (index, change) => {
        const updatedSelected = [...selectedPointAnswers];
        const currentPoints = updatedSelected[currentQuestionIndex].reduce((acc, answer) => acc + answer.points, 0);

        if (change > 0 && currentPoints < 5) {
            updatedSelected[currentQuestionIndex][index].points += change;
        } else if (change < 0 && updatedSelected[currentQuestionIndex][index].points > 0) {
            updatedSelected[currentQuestionIndex][index].points += change;
        }

        setSelectedPointAnswers(updatedSelected);

        console.log('Point Change:', updatedSelected);
    };

    const handleNextQuestion = () => {
        console.log('Current Question Index:', currentQuestionIndex);
        console.log('Displaying Point Questions:', displayingPointQuestions);
        if (!displayingPointQuestions) {
            if (selectedAnswers[currentQuestionIndex].length === 0) {
                setValidationError("Please select at least one answer before proceeding.");
                return;
            }

            setValidationError("");
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setDisplayingPointQuestions(true);
                setCurrentQuestionIndex(0);
            }
        } else {
            const currentPoints = selectedPointAnswers[currentQuestionIndex].reduce((acc, answer) => acc + answer.points, 0);
            if (currentPoints < 5) {
                setValidationError("Please allocate exactly 5 points before proceeding.");
                return;
            }

            setValidationError("");
            if (currentQuestionIndex < pointQuestions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                handleSubmit(); // Submit the answers if it's the last point question
            }
        }

        console.log('Updated Question Index:', currentQuestionIndex);
        console.log('Selected Answers:', selectedAnswers);
        console.log('Selected Point Answers:', selectedPointAnswers);
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        } else if (displayingPointQuestions) {
            setDisplayingPointQuestions(false);
            setCurrentQuestionIndex(questions.length - 1);
        }

        console.log('Previous Question Index:', currentQuestionIndex);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isValidRegisterCode) {
        return <div>Invalid or used register code.</div>;
    }

    const currentQuestion = !displayingPointQuestions ? questions[currentQuestionIndex] : pointQuestions[currentQuestionIndex];
    const currentSelectedAnswers = !displayingPointQuestions ? selectedAnswers[currentQuestionIndex] || [] : [];
    const currentSelectedPointAnswers = displayingPointQuestions ? selectedPointAnswers[currentQuestionIndex] || [] : [];

    if (!currentQuestion || !currentQuestion.answers) {
        return <div>Loading...</div>;
    }

    return (
        <div className="question-container">
            <h2>{currentQuestion.text}</h2>
            <div className="answer-container">
                {!displayingPointQuestions ? (
                    <>
                        <div className="selected-container">
                            {currentSelectedAnswers.map(answer => (
                                <DraggableCard
                                    key={answer.id}
                                    description={answer.text}
                                    onClick={() => handleDeselectAnswer(answer)}
                                />
                            ))}
                        </div>
                        <div className="available-container">
                            {currentQuestion.answers.map(answer => (
                                <DraggableCard
                                    key={answer.id}
                                    description={answer.text}
                                    onClick={() => handleSelectAnswer(answer)}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        {currentQuestion.answers.map((answer, index) => (
                            <div key={answer.id} className="answer-card">
                                <PointCard
                                    description={answer.text}
                                    points={currentSelectedPointAnswers[index].points}
                                    onIncrease={() => handlePointChange(index, 1)}
                                    onDecrease={() => handlePointChange(index, -1)}
                                />
                            </div>
                        ))}
                    </>
                )}
            </div>
            {validationError && <p className="validation-error">{validationError}</p>}
            <div className="navigation-buttons">
                {currentQuestionIndex > 0 || displayingPointQuestions ? (
                    <button className="styled-test-button" onClick={handlePreviousQuestion}>Predošlá otázka</button>
                ) : null}
                {currentQuestionIndex < (!displayingPointQuestions ? questions.length : pointQuestions.length) - 1 ? (
                    <button className="styled-test-button" onClick={handleNextQuestion}>Ďalšia otázka</button>
                ) : (
                    displayingPointQuestions && (
                        <button className="styled-test-button" onClick={handleSubmit}>
                            Poslať odpovede
                        </button>
                    )
                )}
            </div>
        </div>
    );
};

export default ArchetypeScreenPoint;
