import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import './ResearchToLearn.css'; // Create and import a CSS file for styling
import ResearchCard from './ResearchCard';
import CardMenuButton from '../Planet/CardMenuButton';
import ResearchStackVerificationModal from './ResearchStackVerificationModal.js';
import GenerateSignatureModal from './GenerateSignatureModal.js';

import { useNavigate } from 'react-router-dom';

const ResearchLearned = () => {
  
  const [researchs, setResearchs] = useState([]);
  const [error, setError] = useState(null);

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const scrollRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [generatedSignature, setGeneratedSignature] = useState(null);

 const [selectedResearch, setSelectedResearch] = useState(null);


  const navigate = useNavigate(); 
  useEffect(() => {
    const fetchResearchs = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/research/researchLearned', { headers });
        console.log(response.data);
        setResearchs(response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error fetching researchs:', error);
        setError(error.message);
        }
      }
    };

    fetchResearchs();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }


  const handleOpenModal = (research) => {
    console.log("voslooo");
    const element = document.getElementById(`research-${research.id}`);
    if (!element) {
        console.error('Element not found');
        return;
    }
   
    const rect = element.getBoundingClientRect();

    const absolutePositionY = rect.top + scrollRef.current.scrollTop;

    setSelectedResearch(research);
    setIsSignatureModalOpen(true);
    setModalPosition({ x: rect.left, y: absolutePositionY });
};


const handleCreateResearchVerificationRequest = async (researchId, researchKey, text, url, videoFile) => {
  try {
    const model = {
      ResearchId: researchId,
      Key: researchKey,
      Text: text,
      Url: url,
    };
console.log("model", model);
    const token = localStorage.getItem('token');
    const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createResearchVerification', model, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    const verificationRequest = response.data;

    if (verificationRequest && videoFile) {
      const formData = new FormData();
      formData.append('videoFile', videoFile);

      await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/UploadVideo/00000000-0000-0000-0000-000000000000/${verificationRequest.id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
    }
console.log(error);
    setIsModalOpen(false);
  } catch (error) {
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } else {
    console.error('Error fetching researchs:', error);
    setError(error.message);
    }
  }
};

const handleGenerateSignature = async () => {
  try {
    console.log(selectedResearch);
    const token = localStorage.getItem('token');
    const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/generateSignature/research/${selectedResearch.researchId}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    setGeneratedSignature(response.data.signature);
    setIsSignatureModalOpen(false);
    setIsModalOpen(true);
  } catch (error) {
    console.log("error", error);
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    } else {
    console.error('Error fetching researchs:', error);
    }
  }
};




  return (
    <div className="container-screen" ref={scrollRef}>
       <div className="menu">
          <CardMenuButton title="Jednotky" onClick={() => navigate('/g/researchLearned')} />
        <CardMenuButton title="Výskum" onClick={() => navigate('/g/researchLearning')}  />
        <CardMenuButton title="Knižnica" onClick={() => navigate('/g/researchToLearn')}  />
      </div>

      <div className="research-cards-container">
        {researchs.map((research) => (
          <ResearchCard 
             key={research.id} 
             id={`research-${research.id}`} 
             research={research}
             onOpenModal={() => handleOpenModal(research)}
             showCount  
             />
        ))}
         {isSignatureModalOpen && (
        <GenerateSignatureModal
          isOpen={isSignatureModalOpen}
          onClose={() => setIsSignatureModalOpen(false)}
          onConfirm={handleGenerateSignature}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left: '50%', 
            width:'80%',
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}
      {isModalOpen && (
        <ResearchStackVerificationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          research={selectedResearch}
          onCreateResearchVerificationRequest={handleCreateResearchVerificationRequest}
          researchKey={generatedSignature}
          style={{
            position: 'fixed',  // Alebo 'fixed' v závislosti na vašom layoute
            top: `${modalPosition.y}px`,
            left: '50%',
            width:'80%', 
            transform: 'translate(-50%,-50%)' // Aby sa modál vycentroval na uvedené súradnice
        }}
        styleOverlay={{
            height: `${modalPosition.y + 2000}px`
        }}
        />
      )}

      </div>
     
    </div>
  );
};

export default ResearchLearned;
