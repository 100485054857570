// components/ActivityTemplateAdmin.js
import React, { useEffect, useState } from 'react';
import ActivityTemplateTable from './ActivityTemplateTable';
import ActivityTemplateCreateForm from './ActivityTemplateCreateForm';
import axios from 'axios';

const ActivityTemplateAdmin = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/admin/allactivitytemplates', { headers });
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activity templates:', error);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityCreated = (newActivity) => {
    setActivities([...activities, newActivity]);
  };

  return (
    <div>
      <h1>Activity Templates Dashboard</h1>
      <ActivityTemplateTable activities={activities} setActivities={setActivities} />
      <ActivityTemplateCreateForm onActivityCreated={handleActivityCreated} />
    </div>
  );
};

export default ActivityTemplateAdmin;
