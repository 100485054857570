import React from 'react';


import CardMenuButton from '../Planet/CardMenuButton';

import './Market.css';
import { useNavigate } from 'react-router-dom';

const Market = () => {
 

  const navigate = useNavigate();


  
  return (
    <div className="container-screen">
     <div className="menu">
        <CardMenuButton title="Trhovisko" /*onClick={() => navigate("/g/")}*/ />
        <CardMenuButton title="Tabuľa" /*onClick={() => window.location.href = '/g/spell'}*/ />
        <CardMenuButton title="Verifikácie" onClick={() => navigate("/g/verifications")} />
      </div>
    

      <h2>containerMarket</h2>
      
    </div>
  );
};

export default Market;
