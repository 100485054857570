import React from 'react';
import './CombatKeys.css';
import './CombatInteraction.css';
import Troop from './Troop';

const CombatInteraction = ({ summary }) => {
    console.log(summary); // This will log the summary to ensure data is being passed correctly.

    if (!summary) {
        return <p>No combat data available.</p>; // Renders this message if summary is undefined or null.
    }
   /* const troopIcons = {
        1: "/activity/sword.png",
        2: "/activity/bow.png",
        3: "/activity/catapult.png"
    };*/

    return (
        <div className="combat-interaction">
            <h2>Combat Summary</h2>
            <div className="interactions-list">
                {summary.interactions.map((interaction, index) => (
                    <div key={index} className="interaction">
                        {interaction.actionDirection === '=>' ? (
                            <>
                                <div className={`unit-type-icon-interaction ${interaction.attackerType === "1" ? 'sword-icon' : interaction.attackerType === "2" ? 'bow-icon' : 'catapult-icon'}`}>
                                     <div className="unit-type-image-interaction " />
                                </div>
                               
                                <span className="action-direction"> ⇒ </span>
                                <Troop key={interaction.id} id={`troop-${interaction.id}`} troop={interaction} modalType={false} onOpenModal={null} />
                            </>
                        ) : (
                            <>
                            <Troop key={interaction.id} id={`troop-${interaction.id}`} troop={interaction} modalType={false} onOpenModal={null} />
                                <span className="action-direction"> ⇐ </span>
                                <div style={{ transform: 'scaleX(-1)' }}  className={`unit-type-icon-interaction ${interaction.attackerType === "1" ? 'sword-icon' : interaction.attackerType === "2" ? 'bow-icon' : 'catapult-icon'}`}>
                                     <div className="unit-type-image-interaction " />
                                </div>
                               
                            </>
                        )}
                        
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CombatInteraction;
