import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ResearchToLearn.css'; // Create and import a CSS file for styling
import CardMenuButton from '../Planet/CardMenuButton';
import ResearchCardLearning from './ResearchCardLearning';
import { useNavigate } from 'react-router-dom';



const ResearchLearning = () => {
  const [researchs, setResearchs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const notification = sessionStorage.getItem('notification');
    if (notification) {
      alert(notification); // Replace this with your notification system
      sessionStorage.removeItem('notification');
    }

    const fetchResearchs = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/research/researchLearning', { headers });
        setResearchs(response.data);
        console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          console.error('Error fetching researchs:', error);
          setError(error.message);
        }
      }
    };

    fetchResearchs();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-screen">
      <div className="menu">
        <CardMenuButton title="Jednotky" onClick={() => navigate('/g/researchLearned')} />
        <CardMenuButton title="Výskum" onClick={() => navigate('/g/researchLearning')} />
        <CardMenuButton title="Knižnica" onClick={() => navigate('/g/researchToLearn')} />
      </div>
      <div className="research-cards-container">
        {researchs.map((research) => (
          <ResearchCardLearning key={research.id} research={research} />
        ))}
      </div>
    </div>
  );
};

export default ResearchLearning;
