import React, {useState} from 'react';
import './TroopSelectionModal.css'; // Make sure the CSS file contains your styles

const TroopModal = ({ isOpen, onClose, troop, onAddTroopToCombat, onDeleteTroopToCombat, action,style, styleOverlay }) => {
    if (!isOpen) return null;


    const handleCountCombat = () => {
        console.log("troop posiela", troop);
        console.log("slider value", sliderValue);

        if (action == "add"){
            onAddTroopToCombat(troop, sliderValue);
        } else if (action == "delete") {
            onDeleteTroopToCombat(troop, sliderValue);
        }
   

        onClose();  // Zatvoriť modal po pridaní
    };

  



    const [sliderValue, setSliderValue] = useState(1);

    const handleSliderChange = (e) => {
        updateValue(parseInt(e.target.value, 10));
    };

    const handleInputChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        if (!isNaN(newValue) && newValue >= 0 && newValue <= troop.count) {
            setSliderValue(newValue);
        } else {
            setSliderValue(0);  // Reset na minimálnu hodnotu, ak je vstup neplatný
        }
    };

    const updateValue = (newValue) => {
        if (!isNaN(newValue) && newValue >= 0 && newValue <= troop.count) {
            setSliderValue(newValue);
        }
    };

console.log("inicializacny troop", troop);
    return (
        <div className="modal-overlay-troopSelection" style={styleOverlay} onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div className="modal-content-troopSelection" style={style} onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div className="modal-troop-icon-container">
                        <img
                            src={`/activity/${troop.iconName}`}
                            alt={troop.troopTemplateName}
                            className="modal-troop-icon"
                        />
                        <div className="modal-troop-name">{troop.troopTemplateName}</div>
                    </div>
                    <button className="close-button-troopSelection" onClick={onClose}>X</button>
                </div>
                <div className="modal-body">
                    <div className="stats-container">
                            {/* Offensive stats */}
                        <div className="stats-block">
                             <div className="unit-type-icon-attack sword-icon">
                                <div className="unit-type-image-attack" />
                            </div>
                            <div className="stats-value">{troop.physicalAttackInfantry * sliderValue}</div>
                        </div>
                        <div className="stats-block">
                            <div className="unit-type-icon-attack bow-icon">
                                <div className="unit-type-image-attack" />
                            </div>
                            <div className="stats-value">{troop.physicalAttackCavalry * sliderValue}</div>
                        </div>
                        <div className="stats-block">
                            <div className="unit-type-icon-attack catapult-icon">
                                <div className="unit-type-image-attack" />
                            </div>
                            <div className="stats-value">{troop.physicalAttackSiege * sliderValue}</div>
                        </div>
                        {/* Defensive stats */}
                        <div className="stats-block">
                            <div className="unit-type-icon-defense sword-icon">
                                <div className="unit-type-image-defense" />
                            </div>
                            <div className="stats-value">{troop.physicalDefenseInfantry * sliderValue}</div>
                        </div>
                        <div className="stats-block">
                             <div className="unit-type-icon-defense bow-icon">
                                <div className="unit-type-image-defense" />
                            </div>
                            <div className="stats-value">{troop.physicalDefenseCavalry * sliderValue}</div>
                        </div>
                        <div className="stats-block">
                             <div className="unit-type-icon-defense catapult-icon">
                                <div className="unit-type-image-defense" />
                            </div>
                            <div className="stats-value">{troop.physicalDefenseSiege * sliderValue}</div>
                        </div>
                        <p className="description">{troop.description}</p>
                    <div>
                    <label htmlFor="troop-count">Select Troop Count:</label>
                        <input
                            type="range"
                            min="0"
                            max={troop.count}
                            value={sliderValue}
                            className="range-slider"
                            onChange={handleSliderChange}
                        />
                        <input
                            type="number"
                            value={sliderValue}
                            onChange={handleInputChange}
                            className="troop-count-input"
                            min="0"
                            max={troop.count}
                        />
                        <span>/{troop.count}</span>
                    </div>
                    </div>
                    {action =="add" && <button className="styled-button" onClick={handleCountCombat}>Pridať do súboja</button>}
                    {action =="delete" && <button className="styled-button" onClick={handleCountCombat}>Vymazat zo súboja</button>}
                </div>
            </div>
        </div>
    );
};

export default TroopModal;
