// components/AdminPage.js
import React, { useEffect, useState } from 'react';
import TroopTable from './TroopTable';
import TroopCreateForm from './TroopCreateForm';
import axios from 'axios';



const TroopAdmin = () => {
  const [troops, setTroops] = useState([]);



  useEffect(() => {
    const notification = sessionStorage.getItem('notification');
    if (notification) {
      alert(notification); // Replace this with your notification system
      sessionStorage.removeItem('notification');
    }
  const fetchTroops = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        throw new Error('No token found');
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/admin/alltrooptemplates', { headers });
      setTroops(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching troopTemplates:', error);
 
      }
    }
  };

  fetchTroops();
}, []);



  const handleTroopCreated = (newSpot) => {
    setTroops([...troops, newSpot]);
  };

  return (
    <div>
      <h1>Troops Dashboard</h1>
      <TroopTable troops={troops} />
      <TroopCreateForm onTroopCreated={handleTroopCreated} />
    </div>
  );
};

export default TroopAdmin;
