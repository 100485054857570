// components/TroopDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TroopRarityTemplateCreateForm from './TroopRarityTemplateCreateForm';

const TroopDetail = () => {
  const { troopTemplateId } = useParams();
  const [troopRarityTemplates, setTroopRarityTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTroopRarityTemplates = async () => {
      try {
        const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/admin/GetTroopRarityTemplates/${troopTemplateId}`);
        setTroopRarityTemplates(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching troop rarity templates:', error);
      }
    };

    fetchTroopRarityTemplates();
  }, [troopTemplateId]);

  const handleNewTemplateCreated = (newTemplate) => {
    setTroopRarityTemplates([...troopRarityTemplates, newTemplate]);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Troop Detail</h2>
      <p>Troop Template ID: {troopTemplateId}</p>

      <h3>Troop Rarity Templates</h3>
      <table>
  <thead>
    <tr>
      <th>Rarity ID</th>
      <th>Health</th>
      <th>Armor</th>
      <th>Physical Attack Infantry</th>
      <th>Physical Attack Cavalry</th>
      <th>Physical Attack Siege</th>
      <th>Physical Defense Infantry</th>
      <th>Physical Defense Cavalry</th>
      <th>Physical Defense Siege</th>
    {/*  <th>Magical Attack Infantry</th>
      <th>Magical Attack Cavalry</th>
      <th>Magical Attack Siege</th>
      <th>Magical Defense Infantry</th>
      <th>Magical Defense Cavalry</th>
      <th>Magical Defense Siege</th>*/}
    </tr>
  </thead>
  <tbody>
    {troopRarityTemplates.map((template) => (
      <tr key={template.id}>
        <td>{template.rarityId}</td>
        <td>{template.health}</td>
        <td>{template.armor}</td>
        <td>{template.physicalAttackInfantry}</td>
        <td>{template.physicalAttackCavalry}</td>
        <td>{template.physicalAttackSiege}</td>
        <td>{template.physicalDefenseInfantry}</td>
        <td>{template.physicalDefenseCavalry}</td>
        <td>{template.physicalDefenseSiege}</td>
      {/*  <td>{template.magicalAttackInfantry}</td>
        <td>{template.magicalAttackCavalry}</td>
        <td>{template.magicalAttackSiege}</td>
        <td>{template.magicalDefenseInfantry}</td>
        <td>{template.magicalDefenseCavalry}</td>
        <td>{template.magicalDefenseSiege}</td>*/}
      </tr> 
    ))}
  </tbody>
</table>

      <h3>Create New Troop Rarity Template</h3>
      <TroopRarityTemplateCreateForm 
        troopTemplateId={troopTemplateId} 
        onTemplateCreated={handleNewTemplateCreated} 
      />

      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default TroopDetail;