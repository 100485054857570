// components/TroopRarityTemplateCreateForm.js
import React, { useState } from 'react';
import axios from 'axios';

const TroopRarityTemplateCreateForm = ({ troopTemplateId, onTemplateCreated }) => {
  const [template, setTemplate] = useState({
    rarityId: 0,
    health: 0,
    armor: 0,
    physicalAttackInfantry: 0,
    physicalAttackCavalry: 0,
    physicalAttackSiege: 0,
    physicalDefenseInfantry: 0,
    physicalDefenseCavalry: 0,
    physicalDefenseSiege: 0,
    magicalAttackInfantry: 0,
    magicalAttackCavalry: 0,
    magicalAttackSiege: 0,
    magicalDefenseInfantry: 0,
    magicalDefenseCavalry: 0,
    magicalDefenseSiege: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: parseInt(value, 10) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newTemplate = { ...template, troopTemplateId };
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/createTroopRarityTemplate`, newTemplate);
      alert('Troop Rarity Template created successfully!');
      onTemplateCreated(response.data); // Notify parent of the new template
      setTemplate({
        rarityId: 0,
        health: 0,
        armor: 0,
        physicalAttackInfantry: 0,
        physicalAttackCavalry: 0,
        physicalAttackSiege: 0,
        physicalDefenseInfantry: 0,
        physicalDefenseCavalry: 0,
        physicalDefenseSiege: 0,
        magicalAttackInfantry: 0,
        magicalAttackCavalry: 0,
        magicalAttackSiege: 0,
        magicalDefenseInfantry: 0,
        magicalDefenseCavalry: 0,
        magicalDefenseSiege: 0,
      });
    } catch (error) {
      console.error('Failed to create troop rarity template:', error);
      alert('Failed to create troop rarity template');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Rarity ID:
        <input type="number" name="rarityId" value={template.rarityId} onChange={handleChange} />
      </label>
      <label>
        Health:
        <input type="number" name="health" value={template.health} onChange={handleChange} />
      </label>
      <label>
        Armor:
        <input type="number" name="armor" value={template.armor} onChange={handleChange} />
      </label>
      <label>
      physicalAttackInfantry:
        <input type="number" name="physicalAttackInfantry" value={template.physicalAttackInfantry} onChange={handleChange} />
      </label>
      <label>
      physicalAttackCavalry:
        <input type="number" name="physicalAttackCavalry" value={template.physicalAttackCavalry} onChange={handleChange} />
      </label>
      <label>
      physicalAttackSiege:
        <input type="number" name="physicalAttackSiege" value={template.physicalAttackSiege} onChange={handleChange} />
      </label>
      <label>
      physicalDefenseInfantry:
        <input type="number" name="physicalDefenseInfantry" value={template.physicalDefenseInfantry} onChange={handleChange} />
      </label>
      <label>
      physicalDefenseCavalry:
        <input type="number" name="physicalDefenseCavalry" value={template.physicalDefenseCavalry} onChange={handleChange} />
      </label>
      <label>
      physicalDefenseSiege:
        <input type="number" name="physicalDefenseSiege" value={template.physicalDefenseSiege} onChange={handleChange} />
      </label>
         {/*} <label>
      magicalAttackInfantry:
        <input type="number" name="magicalAttackInfantry" value={template.magicalAttackInfantry} onChange={handleChange} />
      </label>

      <label>
      magicalAttackCavalry:
        <input type="number" name="magicalAttackCavalry" value={template.magicalAttackCavalry} onChange={handleChange} />
      </label>
      <label>
      magicalAttackSiege:
        <input type="number" name="magicalAttackSiege" value={template.magicalAttackSiege} onChange={handleChange} />
      </label>
      <label>
      magicalDefenseInfantry:
        <input type="number" name="magicalDefenseInfantry" value={template.magicalDefenseInfantry} onChange={handleChange} />
      </label>
      <label>
      magicalDefenseCavalry:
        <input type="number" name="magicalDefenseCavalry" value={template.magicalDefenseCavalry} onChange={handleChange} />
      </label>
      <label>
      magicalDefenseSiege:
        <input type="number" name="magicalDefenseSiege" value={template.magicalDefenseSiege} onChange={handleChange} />
      </label>*/}

      {/* Add other fields similarly */}
      <button type="submit">Create Troop Rarity Template</button>
    </form>
  );
};

export default TroopRarityTemplateCreateForm;
