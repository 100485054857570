import React from 'react';
import './SpotPopUpWithoutCombat.css';

import { gameAttributes } from '../globalParameters';

import { useNavigate } from 'react-router-dom';


const SpotPopupWithCombat = ({ isOpen, onClose, spot, verifyProximityCombat }) => {

console.log(verifyProximityCombat);
    const navigate = useNavigate();
  
    const renderRewards = (activity) => {
        return (
            <div className="reward-container">
                {Object.keys(gameAttributes).map((key) => {
                    console.log(gameAttributes);
                    const rewardKey = `reward${key.charAt(0).toUpperCase() + key.slice(1)}Points`; // Construct the reward key dynamically
                    const rewardValue = activity.activityLevelTemplate[rewardKey];
                    console.log(rewardKey);
                    console.log(rewardValue);
                    if (rewardValue > 0) {
                        const attribute = gameAttributes[key];
                        console.log("farba", `linear-gradient(to right bottom, ${attribute.hexColorSecond}, ${attribute.hexColorFirst})`);
                        const background = `linear-gradient(to right bottom, ${attribute.hexColorSecond}, ${attribute.hexColorFirst})`;
                        const iconSrc = `/activity/${attribute.nameIcon}`;
                        return (
                            <div key={key} className="attribute-level" style={{ background }}>
                                <div className="marker-icon">
                                    <img src={iconSrc} alt={key} className="attribute-icon" />
                                </div>
                                <img src="/activity/label.png" alt="label" className="label-icon" style={{ width: '30px', position: 'absolute', top: '-10%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                <div className="level-star" style={{ position: 'absolute', top: '-10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>{rewardValue}</div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    if (!isOpen) return null;

    const handleActivitySelection = () => {
        navigate(`/g/combat/${spot.id}`);  // Navigate to CombatSelectionScreen
       // setIsCombatScreenOpen(true);
    };




    return (
        <div className="modal-overlay-spotGenKey">
            <div className="modal-content-spotGenKey">
                <div className="modal-header-spotGenKey">
                    <h3>{spot.name}</h3>
                    <button onClick={handleActivitySelection} className="styled-button">NAJPRV COMBAT !</button>
                </div>
                <div className="modal-body-spotGenKey">
                    {spot.spotActivities.map((activity, index) => (
                        <div key={index}>
                            {renderRewards(activity)}
                            <p className="description">{activity.activityTemplate.description}</p>
                        </div>
                    ))}
                </div>
                <button onClick={onClose} className="styled-button close-button">Zatvoriť</button>
            </div>
        </div>
    );
};

export default SpotPopupWithCombat;
