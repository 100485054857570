import React from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css'; // Ensure you have the corresponding CSS file
import TopPanelOffMenu from './Planet/TopPanelOffMenu';

import BottomNavbarOffMenu from './Planet/BottomNavBarOffMenu';




const LayoutMenuOff = () => {

  
  return (
    <div className="container">
     <TopPanelOffMenu
  className="top-panel"
/>
      <div className="content">
        <Outlet /> {/* This is where nested routes will be rendered */}
      </div>
      <BottomNavbarOffMenu/>
    </div>
  );
};

export default LayoutMenuOff;
