import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BottomNavBar.css';
import { PiPersonArmsSpreadFill } from "react-icons/pi";
import { BsPinMapFill } from "react-icons/bs";
import { GiSwordsEmblem, GiSpellBook } from "react-icons/gi";
import { FaShop } from "react-icons/fa6";

const BottomNavbar = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();

  const openHome = () => {
    setActiveTab('character');
    navigate('/g/character');
  };

  const openMissions = () => {
    setActiveTab('map');
    navigate('/g/mapSpots');
  };

 /* const openPlay = () => {
    setActiveTab('combat');
    navigate('/g/play');
  };
*/
  const openSpells = () => {
    setActiveTab('spell');
    navigate('/g/researchLearned');
  };

  const openChat = () => {
    setActiveTab('market');
    navigate('/g/verifications');
  };

  return (
    <div className="bottom-navbar">
      <button className={activeTab === 'character' ? 'active' : ''} onClick={openHome}>
        <PiPersonArmsSpreadFill className="fa-iconB" />
      </button>
      <button className={activeTab === 'map' ? 'active' : ''} onClick={openMissions}>
        <BsPinMapFill className="fa-iconB" />
      </button>
      <button className={/*activeTab === 'combat' ? 'active' : */''} /*onClick={openPlay}*/>
        <GiSwordsEmblem className="fa-iconB" />
      </button>
      <button className={activeTab === 'spell' ? 'active' : ''} onClick={openSpells}>
        <GiSpellBook className="fa-iconB" />
      </button>
      <button className={activeTab === 'market' ? 'active' : ''} onClick={openChat}>
        <FaShop className="fa-iconB" />
      </button>
    </div>
  );
};

export default BottomNavbar;
