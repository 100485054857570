// components/ItemTemplateTable.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const ItemTemplateTable = ({ items, setItems }) => {
  const [editingItem, setEditingItem] = useState(null);

  const navigate = useNavigate();
  
  const handleEditClick = (item) => {
    setEditingItem(item);
  };

  const handleDetailClick = (itemTemplate) => {
    navigate(`/admin/itemTemplate/${itemTemplate.id}`); // Navigate to the spot details page
  };


  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/admin/updateItemTemplate/${editingItem.id}`, editingItem);
      setItems((prev) => prev.map((item) => (item.id === editingItem.id ? response.data : item)));
      setEditingItem(null); // Close the edit form
    } catch (error) {
      console.error('Error updating item template:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingItem((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Game Attribute</th>
            <th>Item Wear Type</th>
            <th>Level</th>
            <th>Icon Name</th>
            <th>Actions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item?.name}</td>
              <td>{item?.description}</td>
              <td>{item?.gameAttribute?.name}</td>
              <td>{item?.itemWearType?.name}</td>
              <td>{item?.level}</td>
              <td>{item?.iconName}</td>
              <td>
                <button onClick={() => handleEditClick(item)}>Update</button>
              </td>
              <td>
                <button onClick={() => handleDetailClick(item)}>Detail</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingItem && (
        <form onSubmit={handleUpdateSubmit}>
          <h3>Update Item Template</h3>
          <label>
            Name:
            <input type="text" name="name" value={editingItem.name} onChange={handleChange} />
          </label>
          <label>
            Description:
            <input type="text" name="description" value={editingItem.description} onChange={handleChange} />
          </label>
          <label>
            Game Attribute ID:
            <input type="number" name="gameAttributeId" value={editingItem.gameAttributeId} onChange={handleChange} />
          </label>
          <label>
            Item Wear Type ID:
            <input type="text" name="itemWearTypeId" value={editingItem.itemWearTypeId} onChange={handleChange} />
          </label>
          <label>
            Level:
            <input type="number" name="level" value={editingItem.level} onChange={handleChange} />
          </label>
          <label>
            Icon Name:
            <input type="text" name="iconName" value={editingItem.iconName} onChange={handleChange} />
          </label>
          <button type="submit">Save Changes</button>
          <button onClick={() => setEditingItem(null)}>Cancel</button>
        </form>
      )}
    </div>
  );
};

export default ItemTemplateTable;
